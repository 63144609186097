import React from "react";
import styled from "styled-components";
import background from "../../assets/creditnature/images/tree-stump.jpeg";

const ProjectsBanner = () => {
  return (
    <BannerStyled style={{ backgroundImage: `url(${background})` }}>
      <section>
        <p>NATURE IMPACT TOKENS</p>
        <h5>Projects</h5>
        <p>
          Our Nature Impact Tokens offer a robust, transparent, trustworthy and credible way to make nature postive investments.
        </p>
      </section>
    </BannerStyled>
  );
};

export default ProjectsBanner;

const BannerStyled = styled.div`
  height: 40vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
    font-family: "Poppins", sans-serif;

  section {
    display: flex;
    flex-direction: column;
  }

  p,
  h5 {
    color: white !important;
    font-family: "Poppins", sans-serif;
    padding: 0 20vw;

    &:first-child {
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 0.5rem;
      margin-bottom: 3rem;
      opacity: 0.6;
    }
    &:nth-child(2) {
      font-size: 3.5rem;
      margin-bottom: 1rem;
    }
    &:nth-child(3) {
      font-size: 1.8rem;
      margin-top: 1rem;
    }
  }
`;
