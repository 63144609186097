import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import background from "../../assets/creditnature/images/spencer-watson-bYLHitxMHmU-unsplash.jpg";

const InqurieForm = () => {
  return (
    <BannerStyled style={{ backgroundImage: `url(${background})` }}>
      <section>
        <h5>Don't Miss Out On This Revolutionary Opportunity</h5>
        <form
          type="submit"
          onSubmit={(e) => {
            e.preventDefault();
            console.log(e.target[0].value);
            console.log(e.target[1].value);
            console.log(e.target[2].value);
            console.log(e.target[3].value);

            window.open(
              `mailto: info@ecosulis.co.uk?&subject=Request for a call&body=Greetings,%0d%0a%0d%0aI am ${e.target[1].value} ${e.target[2].value} from ${e.target[3].value}.%0d%0aI would like to book a meeting on behalf of my Company.%0d%0a%0d%0aBest Regards,%0d%0a${e.target[1].value}.`
            );
          }}
        >
          <InputGroup className="mb-3">
            <FormControl
              className="text-field"
              placeholder="Email*"
              aria-label="Email"
              aria-describedby="basic-addon1"
              required
            />
          </InputGroup>

          <div className="d-flex flex-row">
            <InputGroup className="mb-3 mr-2">
              <FormControl
                className="text-field"
                placeholder="First Name*"
                aria-label="First Name"
                aria-describedby="basic-addon1"
                required
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <FormControl
                className="text-field"
                placeholder="Last Name*"
                aria-label="Last Name"
                aria-describedby="basic-addon1"
                required
              />
            </InputGroup>
          </div>

          <InputGroup className="mb-3">
            <FormControl
              className="text-field"
              placeholder="Company Name*"
              aria-label="Company Name"
              aria-describedby="basic-addon1"
              required
            />
          </InputGroup>
          <button className="submit-btn">SUBMIT</button>
        </form>
      </section>
    </BannerStyled>
  );
};

export default InqurieForm;

const BannerStyled = styled.div`
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  padding: 0 10vw;
  place-items: center;
  font-family: "Poppins", sans-serif;

  form {
    margin-top: 5rem;
  }
  .submit-btn {
    border-radius: 0;
    padding: 1rem 2rem;
    margin-top: 1rem;
  }
  section {
    display: flex;
    flex-direction: column;
  }
  .text-field {
    font-size: 1.6rem;
    padding: 1rem;
  }
  h5 {
    color: white !important;
    font-size: 3.5rem;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 8vh 10vw;

    form {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        flex-direction: column !important;

        input {
          width: 100% !important;
        }
      }
    }
  }
`;
