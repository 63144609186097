export const NETWORK_ID = 80001;
export const POLYGON_PROVIDER = "https://matic-mumbai.chainstacklabs.com";
export const POLYGON_EXPLORER = "https://mumbai.polygonscan.com/";

export const RINKEBY_PROVIDER =
    "https://rinkeby.infura.io/v3/cc8b5881e3af4a928964f1431fbc6992";
export const RINKEBY_EXPLORER = "https://rinkeby.etherscan.io/";

export const ETHEREUM_MAINNET_PROVIDER =
    "https://mainnet.infura.io/v3/cc8b5881e3af4a928964f1431fbc6992";
export const ETHEREUM_EXPLORER = "https://etherscan.io/";
//export const CONTRACT_ID = "0x9bbfbbd3fffbd0ea6612d0dc7d2479478041428c";

export const MUMBAI_NFT_CONTRACT_ID =
    "0x3F7b15aD118Fe197A36fF53864E4eFd56B2Ab0B5"; //"0x9BBFBBd3FFFBD0ea6612D0Dc7d2479478041428c";
export const RINKEBY_NFT_CONTRACT_ID =
    "0x323837612decd11216d26fdb7c1126f6c555ec7f";
export const ETHEREUM_MAINNET_NFT_CONTRACT_ID = "0xC00f895550bCC74e62440FACf0fB04D8e0d1E8FA";

export const MUMBAI_ELEVATE_MARKET_CONTRACT_ID =
    "0xfEbf12eFCBE4a0be16D0B8FaBe7f1BA56fd11aA2";
export const RINKEBY_ELEVATE_MARKET_CONTRACT_ID = "";
export const ETHEREUM_ELEVATE_MARKET_CONTRACT_ID = "0x712CC6cf15b27b9be91039237f0024853EC52031";
