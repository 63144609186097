import React from "react";
import styled from "styled-components";
import logo from "../../assets/creditnature/images/investor_benefit_logo-removebg-preview.png";
import img from "../../assets/creditnature/images/investor_benefit_1-removebg-preview.png";
import img1 from "../../assets/creditnature/images/investor_benefit_2-removebg-preview.png";
import img2 from "../../assets/creditnature/images/investor_benefit_3-removebg-preview.png";
import img3 from "../../assets/creditnature/images/investor_benefit_4-removebg-preview.png";
import img4 from "../../assets/creditnature/images/investor_benefit_5-removebg-preview.png";
import img5 from "../../assets/creditnature/images/investor_benefit_6-removebg-preview.png";
import img6 from "../../assets/creditnature/images/investor_benefit_7-removebg-preview.png";

const data = [
  {
    img,
    text: "Investible nature positive opportunities that derisk biodiversity and business growth",
  },
  {
    img: img1,
    text: "A stake in verified high quality nature recovery projects in locations with strategic business value",
  },
  {
    img: img2,
    text: "Real-time impact reporting, for every stage of your investment",
  },
  {
    img: img3,
    text: "Enhanced brand reputation and PR value, with credible and measurable impact reporting",
  },
  {
    img: img4,
    text: "Strategic HR opportunities - employee retention, recruitment, and benefit packages",
  },
  {
    img: img5,
    text: "Unlock access to premium nature positive carbon credits",
  },
  {
    img: img6,
    text: "Quantitative and verified reporting of biodiversity",
  },
];

function InvestorBenefits() {
  return (
    <StyledContainer>
      <section>
        <p>NATURE IMPACT TOKENS - INVESTOR BENEFITS</p>
        <img src={logo} alt="logo" />
        <h5>Investor benefits</h5>
        <BrandContainer>
          {data.map((item, index) => (
            <Brand key={index}>
              <img src={item.img} alt="logo" />
              <p>{item.text}</p>
            </Brand>
          ))}
        </BrandContainer>
        <BottomText>
          <p>
            Contact a member of our team today to find out more about the many
            benefits of investing in Nature Impact Tokens email
            info@creditnature.com or schedule a call
          </p>
          <p>
            We're proud to partner with prestigious clients and progressive
            organisations across many sectors, producing consistent, valuable
            results.
          </p>
        </BottomText>
        <button
          onClick={(e) => {
            window.open("https://creditnature.ecosulis.co.uk/more-info#form");
          }}
        >
          REQUEST A CALL →
        </button>
      </section>
    </StyledContainer>
  );
}

export default InvestorBenefits;

const StyledContainer = styled.div`
  background-image: linear-gradient(to right, #097292, #0fdaca);
  display: grid;
  place-items: center;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vw 0;

    > p {
      margin-top: 2rem;
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 0.5rem;
      margin-bottom: 3rem;
      opacity: 0.6;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    > img {
      margin-bottom: 2rem;
    }
  }

  p,
  h5 {
    color: white !important;
    font-family: "Poppins", sans-serif;
  }

  button {
    margin-top: 4rem;
    background-color: white;
    font-weight: bold !important;
    letter-spacing: 0.3rem;
    color: #093842;
    border-radius: 0;
  }

  @media screen and (max-width: 768px) {
    button {
      width: 90%;
    }
  }
`;

const BrandContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20vw;
  justify-content: space-between;
  margin-top: 6rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 0 5rem;
  }
`;

const Brand = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 30%;
  margin: 0 2rem 4rem 0;

  img {
    margin-bottom: 1rem;
  }
  > p {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const BottomText = styled.div`
  margin-top: 4rem;

  > p {
    text-align: left;
    margin-top: 2rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }
`;
