import React from "react";
import styled from "styled-components";
import logo from "../../assets/creditnature/images/investor_benefit_logo-removebg-preview.png";

const ProjectCard = (props) => {
    const { title, size, image, edition } = props;
  return (
    <StyledContainer>
      <div className="circle circle-top"></div>
      <div className="container">
        <section className="section-one">
          <img src={logo} alt="logo" className="logo" />

          <div>
            <h5>PROJECT</h5>
            <h2>{title}</h2>
          </div>
        </section>
        <section className="section-two">
          <div>
            <h5>Size</h5>
            <p>{size}</p>
          </div>
          <div>
            <h5>Edition</h5>
            <p>{edition}</p>
          </div>
        </section>
        <section className="section-three">
          <img src={image} alt="bottom-pic" className="bottom-image" />
        </section>
      </div>
      <div className="circle circle-bottom"></div>
    </StyledContainer>
  );
};

export default ProjectCard;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-top: 2rem;
  font-family: "Poppins", sans-serif;

  .circle {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: white;
  }

  .circle-top {
    position: relative;
    top: 20px;
    z-index: 1;
  }

  .circle-bottom {
    position: relative;
    bottom: 20px;
    z-index: 1;
  }

  .bottom-image {
    object-fit: cover;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;
  }

  .container {
    border: 1px solid grey;
    width: 280px;
    background: linear-gradient(180deg, blue 0%, #549e86 100%);
    border-radius: 45px;
    padding: 0rem;
  }

  .section-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-bottom: 1px solid white;
    padding: 2rem;

    .logo {
      object-fit: contain;
      height: 40px;
      margin-top: 1rem;
      margin-bottom: 8rem;
    }

    > div {
      > h5 {
        text-align: center;
        font-size: 1.8rem;
        color: white;
        letter-spacing: 0.2rem;
      }

      > h2 {
        text-align: center;
        font-size: 2.5rem;
        color: white;
        margin-top: 1rem;
      }
    }
  }

  .section-two {
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      padding-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 0.5;
      margin-bottom: 4rem;
      :first-child {
        border-right: 1px solid white;
      }
      > h5 {
        text-align: center;
        font-size: 1.8rem;
        color: white;
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
      > p {
        text-align: center;
        color: white;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 5vw;
    margin-top: 0;

    .container {
      width: 100%;
    }
  }
`;
