import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { menus, userLoginMenu } from "../../pages/menu";
import DarkMode from "./DarkMode";
import logoheader from "../../assets/images/logo/logo.png";
import logoheader2x from "../../assets/images/logo/logo@2x.png";
import logodark from "../../assets/images/logo/logo_dark.png";
import truncate from "truncate";
import logodark2x from "../../assets/images/logo/logo_dark@2x.png";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, logout } from "../../redux/reducers/userReducer.ts";
import UserMenuDropDown from "./UserMenuDropdown";
import { NETWORK_ID } from "../../static/variables";

const Header = () => {
  const { pathname } = useLocation();
  const user = useSelector(selectUser);
  const walletAddress = user.address;
  const isUserLoggedIn = user.address !== null;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAdmin = user.address === "0x89eaaf9064d3dd94990b9c571383e9c4d2f295de";

  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header?.classList?.add("is-fixed")
      : header?.classList?.remove("is-fixed");
    scrollTop >= 400
      ? header?.classList?.add("is-small")
      : header?.classList?.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);
  const btnSearch = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const searchBtn = () => {
    btnSearch.current.classList.toggle("active");
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
     window.ethereum.on("accountsChanged", async (accounts) => {
      dispatch(logout());
      navigate(`/wallet-connect`);
      // if (window.ethereum.selectedAddress != user.address || window.ethereum.selectedAddress != accounts[0]) {
      //   dispatch(logout());
      //   window.location.reload();
      // }
    });

    window.ethereum.on("chainChanged", async (chain) => {
      if (NETWORK_ID !== parseInt(chain, 16)) {
        dispatch(logout());
        navigate(`/wallet-connect`);
      }
    });
    }
    
  }, [window.ethereum]);

  // useEffect(() => {
  //   window.ethereum.on("accountsChanged", async (accounts) => {
  //     if (accounts.length > 0) {
  //       dispatch(login({ address: accounts[0] }));

  //       const accountBalance = await getUserAccount();
  //       dispatch(updateAccountBalance(accountBalance));

  //       const connectedNetwork = await getNetwork();
  //       dispatch(updateConnectedNetwork(connectedNetwork));
  //     } else {
  //       dispatch(logout());
  //     }
  //   });

  //   window.ethereum.on("chainChanged", async () => {
  //     const connectedNetwork = await getNetwork();
  //     dispatch(updateConnectedNetwork(connectedNetwork));

  //     const accountBalance = await getUserAccount();
  //     dispatch(updateAccountBalance(accountBalance));
  //   });
  // }, [window.ethereum]);

  // useEffect(() => {
  //   if (
  //     window.ethereum.selectedAddress === undefined ||
  //     window.ethereum.selectedAddress === null
  //   ) {
  //     if (user.address) {
  //       handleMetamaskConnect();
  //     }
  //   }
  // }, [window.ethereum.selectedAddress]);

  const onHandleSearch = (event) => {
    event.preventDefault();
    const search = event.target.search.value;
    navigate(`/search?q=${search}`);
  };

  const handleNavigate = (event, submenu) => {
    event.preventDefault();
    if (submenu.isProfile) {
      navigate(`/profile/${walletAddress}`);
    } else if (submenu.isLogout) {
      localStorage.clear();
      window.location.reload();
    } else {
      navigate(submenu.links);
    }
  };

  return (
    <HeaderCustomStyled
      id="header_main"
      className="header_1 js-header"
      ref={headerRef}
    >
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="wrap-box flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="main-logo">
                      <img
                        className="logo-dark"
                        id="logo_header"
                        src={logodark}
                        srcSet={`${logodark2x}`}
                        alt="nft-gaming"
                      />
                      <img
                        className="logo-light"
                        id="logo_header"
                        src={logoheader}
                        srcSet={`${logoheader2x}`}
                        alt="nft-gaming"
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="mobile-button"
                  ref={btnToggle}
                  onClick={menuToggle}
                >
                  <span></span>
                </div>
                {/* <nav id="main-nav" className="main-nav" ref={menuLeft}>
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, index) => {
                      if (data.isAuthRequired && !isUserLoggedIn) {
                        return null;
                      }
                      if (data.isAuthRequired && !isAdmin) {
                        return null;
                      }
                      return (
                        <li
                          key={index}
                          onClick={() => handleOnClick(index)}
                          className={`menu-item ${
                            data.namesub ? "menu-item-has-children" : ""
                          } ${activeIndex === index ? "active" : ""} ${
                            data.isMain && "button"
                          }`}
                        >
                          {!data.isMain && (
                            <Link to={data.links}>{data.name}</Link>
                          )}
                          {data.isMain && (
                            <Link to={!isUserLoggedIn && "/wallet-connect"}>
                              {isUserLoggedIn
                                ? truncate(walletAddress, 10)
                                : "Wallet Connect"}
                            </Link>
                          )}
                          {data.namesub &&
                            (data.isMain ? (
                              isUserLoggedIn && (
                                <ul className="sub-menu">
                                  {data.namesub.map((submenu, index) => {
                                    if (
                                      submenu.isAuthRequired &&
                                      !isUserLoggedIn
                                    ) {
                                      return null;
                                    }

                                    return (
                                      <li
                                        key={submenu.id}
                                        className={
                                          pathname === submenu.links
                                            ? "menu-item current-item"
                                            : "menu-item"
                                        }
                                      >
                                        <Link
                                          to={!data.isMain && submenu.links}
                                          onClick={(event) => {
                                            data.isMain &&
                                              handleNavigate(event, submenu);
                                          }}
                                        >
                                          {submenu.sub}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )
                            ) : (
                              <ul className="sub-menu">
                                {data.namesub.map((submenu, index) => {
                                  if (
                                    submenu.isAuthRequired &&
                                    !isUserLoggedIn
                                  ) {
                                    return null;
                                  }

                                  return (
                                    <li
                                      key={submenu.id}
                                      className={
                                        pathname === submenu.links
                                          ? "menu-item current-item"
                                          : "menu-item"
                                      }
                                    >
                                      <Link
                                        to={!data.isMain && submenu.links}
                                        onClick={() => {
                                          data.isMain &&
                                            handleNavigate(submenu);
                                        }}
                                      >
                                        {submenu.sub}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            ))}
                        </li>
                      );
                    })}
                  </ul>
                </nav> */}
                {/* <div className="flat-search-btn flex">
                  <div className="header-search flat-show-search" id="s1">
                    <Link
                      to="#"
                      className="show-search header-search-trigger"
                      onClick={searchBtn}
                    >
                      <i className="far fa-search"></i>
                    </Link>
                    <div className="top-search" ref={btnSearch}>
                      <form  role="search" className="search-form" onSubmit={onHandleSearch}>
                        <input type="search" id="search" className="search-field" placeholder="Search..." name="search" title="Search for" required="" />
                        <button className="search search-submit" type="submit" title="Search">
                          <i className="icon-fl-search-filled"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DarkMode /> */}
    </HeaderCustomStyled>
  );
};

export default Header;

const HeaderCustomStyled = styled.div`
  .button {
    background-color: #097292;
    width: 180px;
    font-size: 1.3rem;
  }
  .MuiMenuItem-root {
    border: 10px red solid !important;
    background-color: red !important;
  }
  @media (max-width: 500px) {
    .flat-show-search {
      display: none;
    }
  }
`;
