import { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import truncate from "truncate";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/reducers/userReducer.ts";
import { useSelector } from "react-redux";

const UserMenuDropDown = () => {
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const dropdownOpen = Boolean(dropdownAnchor);
  const user = useSelector(selectUser);
  const walletAddress = user.address;
  const navigate = useNavigate();
  const isUserLoggedIn = user.address !== null;

  const handleDropdownOpen = (event) => {
    if (isUserLoggedIn) {
      setDropdownAnchor(event.currentTarget);
    } else {
      navigate("/wallet-connect");
    }
  };

  const handleDropdownClose = () => {
    setDropdownAnchor(null);
  };

  const menuOptions = [
    {
      label: "My Profile",
      handleOnClick: () => {
        navigate(`/profile/${walletAddress}`);
      },
    },
    {
      label: "Edit Profile",
      handleOnClick: () => {
        navigate("/edit-profile");
      },
    },
    {
      label: "Logout",
      handleOnClick: () => {
        localStorage.clear();
        window.location.reload();
      },
    },
  ];

  return (
    <MenuContainerStyled>
      <Button
        id="dropdown-button"
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        aria-expanded={dropdownOpen ? "true" : undefined}
        onClick={handleDropdownOpen}
      >
        {!isUserLoggedIn ? (
          "Wallet Connect"
        ) : (
          <>
            <span>{truncate(walletAddress, 10)}</span>
            <ArrowDropDownIcon fontSize="large" />
          </>
        )}
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={dropdownAnchor}
        open={dropdownOpen}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleDropdownClose}
        MenuListProps={{
          "aria-labelledby": "dropdown-button",
        }}
      >
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={option.handleOnClick}
            style={{
              fontSize: "1.2rem",
              width: "160px",
              textTransform: "uppercase",
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </MenuContainerStyled>
  );
};

export default UserMenuDropDown;

const MenuContainerStyled = styled.div`
  > button {
    background-color: #097292;
    color: white;
    width: 150px;
    border-radius: 50px;
    padding: 0.6pc;
    font-size: 1.3rem;
  }

  .MuiMenuItem-root {
    border: 10px red solid !important;
    background-color: red !important;
  }
`;
