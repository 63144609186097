import React from "react";
import styled from "styled-components";
import background from "../../assets/creditnature/images/spencer-watson-bYLHitxMHmU-unsplash.jpg";
import { useNavigate } from "react-router-dom";

const MainBanner = () => {
  const navigate = useNavigate();

  return (
    <BannerStyled style={{ backgroundImage: `url(${background})` }}>
      <section>
        <p>MARKETPLACE</p>
        <h1>Nature Impact Tokens</h1>
        <p>INVESTING IN GLOBAL REWILDING</p>
        <button
          onClick={(e) =>
            navigate(`/collection/63470141240cb4054f2317a7`)
          }

          // onClick={(e)=>{
          //   window.open("https://creditnature.ecosulis.co.uk/more-info")
          // }}
        >
          INVEST IN A PROJECT →
        </button>
      </section>
    </BannerStyled>
  );
};

export default MainBanner;

const BannerStyled = styled.div`
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p,
  h1 {
    color: white !important;
    text-align: center;
    font-family: "Poppins", sans-serif;

    &:first-child {
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 0.5rem;
      margin-bottom: 4rem;
    }

    &:nth-child(3) {
      font-size: 1.5rem;
      font-weight: bold;
      opacity: 0.6;
      letter-spacing: 0.5rem;
      margin-top: 1rem;
    }
  }
  button {
    margin-top: 8rem;

    background-color: white;
    font-weight: bold !important;
    letter-spacing: 0.3rem;
    color: #093842;
    border-radius: 0;
  }
`;
