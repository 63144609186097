import React from "react";
import styled from "styled-components";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Modal } from "react-bootstrap";

const ProgressModal = (props) => {
  const { show, onHide, nft, isApproveListingProgress, isConfirmListingProgress, isTriggeredConfirmedListingReq } = props;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton />
      <section className="modal-body space-y-10 pd-40">
        <h3>Complete your listing</h3>
      </section>

      <section>
        <div className="d-flex mb-2 justify-content-around">
          <p>Title: </p>
          <p className="text-right price color-popup">
            {nft?.title}
          </p>
        </div>

        {/* <div className="d-flex mb-2 justify-content-around">
          <p>Description: </p>
          <p className="text-right price color-popup">
            {nft?.description}
          </p>
        </div> */}

        <div className="d-flex mb-2 justify-content-around">
          <p>Total price: </p>
          <p className="text-right price color-popup">
            {nft?.price} {"USD"}
          </p>
        </div>
      </section>

      <SeperatorStyled />

      <section className="modal-text space-y-10 pd-40">
        <h4>NFT Approval</h4>
        {isApproveListingProgress ? <>
          <p>Approving</p>
          <br />
          <CircularProgress size={30} style={{ color: 'white' }} />
        </> : <>
          <p>Approved</p>
          <br />
          <CheckCircleOutlineIcon style={{ fontSize: '3rem' }} />
        </>}
      </section>

      <SeperatorStyled />

      <section className="modal-text space-y-10 pd-40">
        <h4>NFT Listing</h4>
        {isTriggeredConfirmedListingReq ? <>
          <p>Confirmation pending....</p>
          <br />
        </> : <>
          {!isConfirmListingProgress ? (
            <>
              <p>Confirming</p>
              <br />
              <CircularProgress size={30} style={{ color: 'white' }} />
            </>
          ) : (
            <>
              <p>Confirmed</p>
              <br />
              <CheckCircleOutlineIcon style={{ fontSize: '3rem' }} />
            </>
          )}
        </>}
      </section>

      <button style={{
        width: '80%', margin: '20px auto',
      }} onClick={onHide}>
        Close
      </button>
    </Modal>
  );
};

export default ProgressModal;

const SeperatorStyled = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 1px;
  background-color: #e5e5e5;
  margin: 20px 0;
`;


