import Switch from "react-switch";
import styled from "styled-components";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import img1 from "../assets/images/avatar/avt-3.jpg";
import { Link, useParams } from "react-router-dom";
import { CircularProgress, Snackbar } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineHeart, AiFillHeart, AiOutlineEye } from "react-icons/ai";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import CardModal from "../components/layouts/CardModal";
import EditNFTModal from "../components/layouts/EditNFTModal";
import {
  selectUser,
  selectUserDetails,
} from "../redux/reducers/userReducer.ts";

import { NftAction } from "../enum/Nft.ts";
import { ActivityType } from "../enum/Activity.ts";
import { NFTService } from "../services/nft.service";
import { createActivityService } from "../services/activity.service.ts";
import { useCallback } from "react";
import Web3 from "web3";
import ReactPlayer from "react-player";
import truncate from "truncate";
import {
  ETHEREUM_ELEVATE_MARKET_CONTRACT_ID,
  ETHEREUM_MAINNET_NFT_CONTRACT_ID,
  MUMBAI_ELEVATE_MARKET_CONTRACT_ID,
  MUMBAI_NFT_CONTRACT_ID,
  RINKEBY_ELEVATE_MARKET_CONTRACT_ID,
  RINKEBY_NFT_CONTRACT_ID,
} from "../static/variables";
import ProgressModal from "../components/layouts/ProgressModal";
import SnackAction from "../components/snackAction/SnackAction";
import { tokenExtraDetails } from "./tokenExtraDetails";

const NFTDetails = () => {
  const [biddingHistoryData, setBiddingHistoryData] = useState([]);
  const [isLoadingBiddingHistory, setIsLoadingBiddingHistory] = useState(false);
  const [isLoadingNetworkCalls, setIsLoadingNetworkCalls] = useState(false);

  const nftService = useMemo(() => new NFTService(), []);
  const user = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);
  const { id } = useParams();
  const accessToken = user.accessToken;

  const [nft, setNft] = useState();
  // const [liveAuctions, setLiveAuctions] = useState();
  // const [triggerTimeRefetch, setTriggerTimeRefetch] = useState(false);
  const [triggerSingleRefetch, setTriggerSingleRefetch] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isItemExpired, setIsItemExpired] = useState(false);

  const [toEditMethod, setToEditMethod] = useState("Fixed Price");
  const [toEditPrice, setToEditPrice] = useState();
  const [toEditMinBid, setToEditMinBid] = useState();
  const [toEditStartingDate, setToEditStartingDate] = useState();
  const [toEditEndingDate, setToEditEndingDate] = useState();

  const [showEditNFTModal, setShowEditNFTModal] = useState(false);
  const [isToggleLike, setisToggleLike] = useState(false);
  const [isUserViewed, setIsUserViewed] = useState(false);
  const [isUserViewedToggle, setIsUserViewedToggle] = useState(false);
  const [isLikeHandleLoading, setIsLikeHandleLoading] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [putOnMP, setPutOnMP] = useState(true);
  const [network, setNetwork] = useState();
  const [metamaskData, setmetamaskData] = useState(undefined);
  const [price, setPrice] = useState(0);
  const [isDisplayPriceField, setIsDisplayPriceField] = useState(false);
  const [message, setMessage] = useState("");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [notify, setNotify] = useState();
  const [displayNotification, setDisplayNotification] = useState(false);
  const defaultUserProfileImage =
    "https://niftron.infura-ipfs.io/ipfs/QmcFee3QWZLin6tkHJvDC2o3pXQcSnoJHWDX3k3S5a43tQ";
  const adminAddress = "0x89eaaf9064d3dd94990b9c571383e9c4d2f295de";

  const [isApproveListingProgress, setIsApproveListingProgress] =
    useState(false);
  const [isConfirmListingProgress, setIsConfirmListingProgress] =
    useState(false);
  const [isTriggeredConfirmedListingReq, setIsTriggeredConfirmedListingReq] =
    useState(false);
  const [showListingModal, setShowListingModal] = useState(false);

  const handleToggleLike = async () => {
    const userId = userDetails._id;
    const nftId = id;
    setIsLikeHandleLoading(true);
    let type = isToggleLike ? NftAction.Dislike : NftAction.Like;

    await nftService
      .handleLikeNft(userId, nftId, type)
      .then(() => {
        setisToggleLike(!isToggleLike);
        if (isToggleLike) {
          setLikeCount(likeCount - 1);
        } else {
          setLikeCount(likeCount + 1);
        }
      })
      .finally(() => {
        setIsLikeHandleLoading(false);
      });
  };

  // useEffect(() => {
  //   const getInitialData = async () => {
  //     const timeAuctionData = (await nftService.getNFTsOfType("Time Auctions"))
  //       .nfts;
  //     setLiveAuctions(timeAuctionData);
  //   };

  //   getInitialData();
  // }, [nftService, triggerTimeRefetch]);

  const handleEndAuction = async () => {};

  const handleBiddingHistory = useCallback(async () => {
    if (id) {
      setIsLoadingBiddingHistory(true);
      const biddingHistory = (await nftService.getNftBiddingHistory(id))
        .nftBidHistory;
      console.log(biddingHistory);
      const dataFormatted = biddingHistory?.map((bidHistory) => {
        return {
          displayName: bidHistory.bidder.displayName,
          address: bidHistory.bidder.address,
          bidAmount: bidHistory.bisAmount,
          createdAt: bidHistory.createdAt,
        };
      });
      dataFormatted?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setBiddingHistoryData(dataFormatted);
      setIsLoadingBiddingHistory(false);
    }
  }, [id, nftService]);

  useEffect(() => {
    handleBiddingHistory();
  }, [handleBiddingHistory]);

  useEffect(() => {
    const getInitialData = async () => {
      const nftData = (await nftService.getSingleNFT(id)).nft;
      const usersLiked = nftData.usersLiked || [];
      const usersViewed = nftData.usersViewed || [];
      const userId = userDetails._id;
      const likeCount = nftData.likes || 0;
      const metadataHash = nftData.metadata;
      const metadata = await nftService.getIpfsMetadata(metadataHash);
      console.log("metadata", metadata);

      const isLiked = usersLiked.includes(userId);
      const isViewed = usersViewed.includes(userId);

      setmetamaskData(metadata);
      setLikeCount(likeCount);
      setisToggleLike(isLiked);
      setIsUserViewed(isViewed);
      setPutOnMP(nftData.putOnMarket);
      setNft(nftData);
      setIsItemExpired(Date.now() > new Date(nftData.endingDate).getTime());
    };
    //console.log(nftData);
    getInitialData();
  }, [triggerSingleRefetch, nftService, id, userDetails]);

  useEffect(() => {
    if (!isUserViewed && accessToken && !isUserViewedToggle && id && nft) {
      const updateUserViewNft = async () => {
        const userId = userDetails._id;
        const nftId = id;

        const activityRequestBody = {
          fromUser: userId,
          nftId: id,
          type: ActivityType.NftView,
        };

        await nftService
          .handleAddViewNft(userId, nftId)
          .then(async () => {
            await createActivityService(activityRequestBody, accessToken)
              .then(() => {
                setIsUserViewedToggle(true);
                setIsUserViewed(true);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      };
      updateUserViewNft();
    }
  }, [
    isUserViewed,
    isUserViewedToggle,
    accessToken,
    nftService,
    id,
    userDetails,
    nft,
  ]);

  const buyItem = async (amount) => {
    setTriggerSingleRefetch(false);
    await nftService.buyNFT(
      selectedItem,
      selectedItem._id,
      user.address,
      amount.seller,
      amount.costInUSD,
      amount.costInCrypto,
      amount.serviceFee,
      amount.totalCost,
      amount.currency,
      amount.txnHash,
      amount.network,
      amount.status
    );
    setTriggerSingleRefetch(true);
    setModalShow(false);
  };

  const handleAddPriceNft = async () => {
    if (price === 0) {
      setDisplayMessage(true);
      setMessage("Price cannot be zero");
      return;
    } else {
      setDisplayMessage(false);
      setMessage("");
    }

    setIsLoadingNetworkCalls(true);

    console.log("working on adding the price");

    // ----- without blockchain part -------

    // await nftService.editNFT(nft._id, {
    //   ...nft,
    //   price: price,
    //   putOnMarket: true,
    // }).then(() => {
    //   setIsDisplayPriceField(false);
    //   setPutOnMP(true);
    //   setTriggerSingleRefetch(true);
    // }).catch((error) => { console.log(error) })
    // .finally(() => { setIsLoadingNetworkCalls(false) });

    // -------------------------------------

    // ----- with blockchain part -------
    setIsApproveListingProgress(true);
    const web3 = new Web3(window.ethereum);
    const mpStatus = !putOnMP;
    const price_ = price;
    const tokenId = nft.tokenId;
    const priceInWei = web3.utils.toBN(parseFloat(price) * 1e18);
    const netId = await web3.eth.net.getId();
    let nftContractId;
    let marketContractId;
    if (netId === 1) {
      nftContractId = ETHEREUM_MAINNET_NFT_CONTRACT_ID;
      marketContractId = ETHEREUM_ELEVATE_MARKET_CONTRACT_ID;
    }
    if (netId === 4) {
      nftContractId = RINKEBY_NFT_CONTRACT_ID;
      marketContractId = RINKEBY_ELEVATE_MARKET_CONTRACT_ID;
    }
    if (netId === 80001) {
      nftContractId = MUMBAI_NFT_CONTRACT_ID;
      marketContractId = MUMBAI_ELEVATE_MARKET_CONTRACT_ID;
    }
    const nftContractABI = require("../utils/abi/ENFTABI.json");
    const ELEVATEMARKETABI = require("../utils/abi/ElevateMarketABI.json");

    const ENFTContract = new web3.eth.Contract(nftContractABI, nftContractId);
    const ElevateMarketContract = new web3.eth.Contract(
      ELEVATEMARKETABI,
      marketContractId
    );
    let isApprovedAddress = await ENFTContract.methods
      .getApproved(tokenId)
      .call();
    console.log(isApprovedAddress);
    try {
      setShowListingModal(true);

      if (isApprovedAddress != marketContractId) {
        await ENFTContract.methods
          .approve(marketContractId, tokenId)
          .send({ from: user.address })
          .on("receipt", async (receipt) => {
            setIsApproveListingProgress(false);
            setIsTriggeredConfirmedListingReq(true);
            await ElevateMarketContract.methods
              .listNFTForSale(nftContractId, tokenId, priceInWei)
              .send({ from: user.address })
              .on("transactionHash", async (hash) => {
                const txnHash = hash;
                await nftService.editNFT(nft._id, {
                  ...nft,
                  price: price_,
                  putOnMarket: mpStatus,
                  escrowNFTTxnHash: txnHash,
                });
              })
              .on("receipt", async (receipt) => {
                setIsTriggeredConfirmedListingReq(false);
                setIsConfirmListingProgress(true);
              });
          });
      } else {
        setIsApproveListingProgress(false);
        setIsTriggeredConfirmedListingReq(true);

        await ElevateMarketContract.methods
          .listNFTForSale(nftContractId, tokenId, priceInWei)
          .send({ from: user.address })
          .on("transactionHash", async (hash) => {
            setIsDisplayPriceField(false);
            const txnHash = hash;
            await nftService.editNFT(nft._id, {
              ...nft,
              price: price_,
              putOnMarket: mpStatus,
              escrowNFTTxnHash: txnHash,
            });
          })
          .on("receipt", async (receipt) => {
            setIsTriggeredConfirmedListingReq(false);
            setIsConfirmListingProgress(true);
          });
      }
    } catch (error) {
    } finally {
      setIsLoadingNetworkCalls(false);
      setTriggerSingleRefetch(true);
    }

    //try {
    // await ElevateNFT.methods
    //   .lockNFTForSale(tokenId, priceInWei)
    //   .send({ from: user.address })
    //   .on("receipt", async (receipt) => {
    //     const hash = receipt.transactionHash;
    //     await nftService
    //       .editNFT(nft._id, {
    //         ...nft,
    //         price: price_,
    //         putOnMarket: mpStatus,
    //         escrowNFTTxnHash: hash,
    //       })
    //       .then(() => {
    //         console.log("........4");
    //         setIsDisplayPriceField(false);
    //         setPutOnMP(true);
    //         setTriggerSingleRefetch(true);
    //       });
    //     // }
    //   });
    //} catch (error) {}
  };

  const bidOnItem = async (amount) => {
    setTriggerSingleRefetch(false);
    console.log("triggering bid on ite....");
    console.log(amount);
    await nftService
      .bidOnNFT(
        selectedItem,
        selectedItem._id,
        amount.amount,
        amount.transactionHash,
        amount.networkId,
        user.address
      )
      .then(async () => {
        console.log("trigger bid on ite success");
        const biddingActivity = {
          nftId: selectedItem._id,
          userAddress: user.address,
          bid: amount,
          type: ActivityType.NftBidding,
        };
        await createActivityService(biddingActivity, accessToken).then(() => {
          console.log("create activity success for bidding");
        });
        handleBiddingHistory();
      });
    setTriggerSingleRefetch(true);
    setModalShow(false);
  };

  const handleMintNFT = async () => {
    console.log(nft);
    console.log(user);

    const userAddress = user.address;
    const tokenURI = nft.metadata;
    const royalties = nft.royalties;

    const royalty = Number(royalties) * 10 ** 2;
    console.log(royalty);

    const web3 = new Web3(window.ethereum);

    const netId = await web3.eth.net.getId();
    let nftContractId;
    if (netId === 1) nftContractId = ETHEREUM_MAINNET_NFT_CONTRACT_ID;
    if (netId === 4) nftContractId = RINKEBY_NFT_CONTRACT_ID;
    if (netId === 80001) nftContractId = MUMBAI_NFT_CONTRACT_ID;
    const contractABI = require("../utils/abi/ENFTABI.json");
    const NFTCONTRACT = new web3.eth.Contract(contractABI, nftContractId);

    setTriggerSingleRefetch(false);
    await NFTCONTRACT.methods
      .mintWithRoyalty(userAddress, tokenURI, nft.royaltyPublicKey, royalty)
      .send({ from: user.address })
      .on("transactionHash", async (hash) => {
        console.log(hash);
        await nftService
          .editNFT(nft._id, {
            ...nft,
            mintTxnHash: hash,
            mintInitiated: true,
          })
          .then(() => {
            setNotify("NFT minted successfully");
            setDisplayNotification(true);
            // alert("Minting ran successfully....");
            // setTriggerSingleRefetch(true);
            window.location.reload();
          });
      });
  };

  const updateOnMPStatus = async () => {
    const mpStatus = !putOnMP;
    console.log(mpStatus);
    if (mpStatus) {
      // onEditNFTClick();
      setIsDisplayPriceField(true);
    } else {
      console.log("toggle is set to false");
      const web3 = new Web3(window.ethereum);
      const tokenId = nft.tokenId;
      const netId = await web3.eth.net.getId();
      let nftContractId;
      let marketContractId;
      if (netId === 1) {
        nftContractId = ETHEREUM_MAINNET_NFT_CONTRACT_ID;
        marketContractId = ETHEREUM_ELEVATE_MARKET_CONTRACT_ID;
      }
      if (netId === 4) {
        nftContractId = RINKEBY_NFT_CONTRACT_ID;
        marketContractId = RINKEBY_ELEVATE_MARKET_CONTRACT_ID;
      }
      if (netId === 80001) {
        nftContractId = MUMBAI_NFT_CONTRACT_ID;
        marketContractId = MUMBAI_ELEVATE_MARKET_CONTRACT_ID;
      }
      const ELEVATEMARKETABI = require("../utils/abi/ElevateMarketABI.json");

      const ElevateMarketContract = new web3.eth.Contract(
        ELEVATEMARKETABI,
        marketContractId
      );

      await ElevateMarketContract.methods
        .cancelListing(nftContractId, tokenId)
        .send({ from: user.address })
        .on("receipt", async (receipt) => {
          // canceling the listing ui display
          setPutOnMP(false);
          await nftService.editNFT(nft._id, {
            ...nft,
            putOnMarket: mpStatus,
          });
        });
      // setPutOnMP(false);
      // await nftService.editNFT(nft._id, {
      //   ...nft,
      //   putOnMarket: mpStatus,
      // });
    }
  };

  const onEditNFTClick = () => {
    setToEditPrice(nft.price || "");
    setToEditMinBid(nft.minBid || "");
    setToEditStartingDate(nft.startingDate || "");
    setToEditEndingDate(nft.endDate || "");
    setShowEditNFTModal(true);
  };

  const onPutOnSale = async () => {
    console.log("on putting on sale toEditMethod:", toEditMethod);
    switch (toEditMethod) {
      case "Time Auctions": {
        await nftService.editNFT(nft._id, {
          ...nft,
          minBid: toEditMinBid,
          currentBid: toEditMinBid,
          bidderAddress: user.address,
          startingDate: toEditStartingDate,
          endingDate: toEditEndingDate,
          method: toEditMethod,
          putOnMarket: true,
        });
        break;
      }
      case "Open For Bids": {
        await nftService.editNFT(nft._id, {
          ...nft,
          minBid: toEditMinBid,
          currentBid: toEditMinBid,
          bidderAddress: user.address,
          startingDate: new Date().toISOString().split("T")[0],
          endingDate: toEditEndingDate,
          method: "Time Auctions",
          putOnMarket: true,
        });
        break;
      }
      case "Fixed Price":
        const {
          startingDate,
          endingDate,
          currentBid,
          currentBidder,
          minBid,
          ...rest
        } = nft;
        console.log(rest);
        await nftService.editNFT(nft._id, {
          ...rest,
          price: toEditPrice,
          method: toEditMethod,
          putOnMarket: true,
        });
        break;
      default: {
        break;
      }
    }

    setPutOnMP(true);
  };

  const FileContent = () =>
    !nft.isFileVideo ? (
      <img
        src={nft.file2 || nft.file || img1}
        alt="Axies"
        style={{ maxHeight: "400px" }}
      />
    ) : (
      <ReactPlayer width="100%" controls playing loop url={nft.file} />
    );

  return (
    <div className="item-details">
      <Header />
      {!nft ? (
        <ProgressContainer>
          <CircularProgress color="primary" />
        </ProgressContainer>
      ) : (
        <StyledContainer nft={nft}>
          <section className="flat-title-page inner">
            <div className="overlay-updated"></div>
            <div className="themesflat-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center">{nft.title}</h1>
                  </div>
                  <p className="text-center">{nft.description}</p>
                </div>
              </div>
            </div>
          </section>
          {/* {!nft || !liveAuctions ? ( */}
          <>
            <div className="tf-section tf-item-details style-2">
              <div className="themesflat-container">
                <div className="row">
                  {/* <div className="col-xl-3 col-md-4 col-sm-12">
                    <div className="content-center">
                      <div
                        className="media"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <FileContent />
                        <p className="mt-3">
                          Price per token: {tokenExtraDetails.pricePerToken}
                        </p>
                        <p>
                          Number of tokens available:{" "}
                          {tokenExtraDetails.noAvailable}/
                          {tokenExtraDetails.noTotal}
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <MainBodyContainer>
                    <section className="body_container_top">
                      <div className="body_container_item1">
                        <FileContent />
                      </div>

                      <div className="body_container_item2">
                        <div className="meta-info">
                          <div className="author">
                            <div className="avatar">
                              <img
                                src={
                                  nft?.ownerDetails?.profileImage ||
                                  defaultUserProfileImage
                                }
                                alt="Axies"
                              />
                            </div>
                            <div className="info">
                              <span>Owned By</span>
                              <h6>
                                <Link to={`/profile/${nft.owner}`}>
                                  {nft?.ownerDetails?.displayName ||
                                    truncate(nft.owner, 18)}
                                </Link>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="meta-item-details">
                          <div className="item-style-2 item-details">
                            <ul className="list-details">
                              <li>
                                <span>Created At : </span>
                                <h6>
                                  {new Date(nft.createdAt).toDateString()}
                                </h6>{" "}
                              </li>

                              <li>
                                <span>Collection : </span>
                                <h6>{nft.collectionDetails.name}</h6>
                              </li>

                              {nft.tokenId && (
                                <li>
                                  <span>Token id : </span>
                                  <h6>{nft.tokenId}</h6>
                                </li>
                              )}

                              {metamaskData && metamaskData.fee_recipient && (
                                <li>
                                  <span>Royality Recipiant : </span>
                                  <h6>
                                    {truncate(metamaskData.fee_recipient, 15)}
                                  </h6>
                                </li>
                              )}

                              {metamaskData &&
                                metamaskData.seller_fee_basis_points && (
                                  <li>
                                    <span>Royality Percentage : </span>
                                    <h6>
                                      {metamaskData.seller_fee_basis_points /
                                        100}
                                      %
                                    </h6>
                                  </li>
                                )}

                              {metamaskData &&
                                metamaskData.attributes.length > 0 &&
                                metamaskData.attributes.map((attribute) => {
                                  return (
                                    <li>
                                      {attribute.trait_type && (
                                        <span>
                                          {attribute.trait_type}:{" "}
                                          <strong>{attribute.value}</strong>{" "}
                                        </span>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="body_container_item3">
                        <div className="meta-info">
                          <div className="author">
                            <div className="avatar">
                              <img
                                src={
                                  nft?.creatorDetails?.profileImage ||
                                  defaultUserProfileImage
                                }
                                alt="Axies"
                              />
                            </div>
                            <div className="info">
                              <span>Created By</span>
                              <h6>
                                {" "}
                                <Link to={`/profile/${nft.creator}`}>
                                  {nft?.creatorDetails?.displayName ||
                                    truncate(nft.creator, 18)}
                                </Link>{" "}
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="action_buttons">
                          <button
                            onClick={() => {
                              window.open(
                                "http://explorer.deprofile.io/depro/Project001"
                              );
                            }}
                          >
                            DePro
                          </button>
                          <button
                            className="mt-4"
                            // onClick={handleEndAuction}
                          >
                            Documents
                          </button>
                        </div>
                      </div>
                      <div className="body_container_item4">
                        <div className="image_container">
                          <img src={nft.file} alt="Axies" width={500} />
                        </div>
                      </div>
                    </section>

                    <section className="body_container_bottom">
                      <div className="bottom_container">
                        {tokenExtraDetails.descriptions.map((desc) => (
                          <div className="col-xl-4 col-md-6 col-sm-12">
                            <h3 className="mb-4">{desc.title}</h3>
                            <ul>
                              {desc.points.map((point) => (
                                <p>{point}</p>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </section>
                  </MainBodyContainer>
                  <br />
                  
                </div>
              </div>
              <br />{" "}
            </div>
          </>

          {selectedItem && (
            <CardModal
              action={(amount) =>
                nft.startingDate ? bidOnItem(amount) : buyItem(amount)
              }
              selectedItem={selectedItem}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          )}

          <ProgressModal
            nft={nft}
            show={showListingModal}
            onHide={() => {
              setShowListingModal(false);
              window.location.reload();
            }}
            isApproveListingProgress={isApproveListingProgress}
            isConfirmListingProgress={isConfirmListingProgress}
            isTriggeredConfirmedListingReq={isTriggeredConfirmedListingReq}
          />

          <EditNFTModal
            show={showEditNFTModal}
            onHide={() => setShowEditNFTModal(false)}
            method={toEditMethod}
            setMethod={setToEditMethod}
            price={toEditPrice}
            setPrice={setToEditPrice}
            minBid={toEditMinBid}
            setMinBid={setToEditMinBid}
            startingDate={toEditStartingDate}
            setStartingDate={setToEditStartingDate}
            endingDate={toEditEndingDate}
            setEndingDate={setToEditEndingDate}
            putOnSale={onPutOnSale}
            setTriggerSingleRefetch={setTriggerSingleRefetch}
          />
          <Footer />
          <Snackbar
            className="snackbar-text"
            open={displayNotification}
            autoHideDuration={6000}
            onClose={() => {
              setDisplayNotification(false);
            }}
            message={notify}
            action={<SnackAction handleClose={setDisplayNotification} />}
          />
        </StyledContainer>
      )}
    </div>
  );
};

export default NFTDetails;

const StyledContainer = styled.div`
`;

const PriceContainer = styled.div`
  display: flex;
  padding: 1pc;
  margin: 2pc 0;
  align-items: center;
  justify-content: space-between;

  > input {
    margin: 0 1pc;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  > * {
    color: #fff !important;
  }
`;

const ViewLikeSection = styled.div`
  display: flex;
  align-items: center;

  .nft-views,
  .nft-likes {
    display: flex;
    align-items: center;

    > span {
      padding: 0.1pc 0.6pc !important;
    }
  }

  .circular-progress {
    color: white !important;
  }

  .nft-likes {
    cursor: pointer;
  }
`;

const AdditionalInformation = styled.div`
  /* background-color: #14141F; */
  display: flex;
  width: 1440px;
  justify-content: space-around;
  padding-bottom: 2rem;
`;

const MainBodyContainer = styled.div`
  width: 100%;
  .body_container_top,
  .body_container_bottom {
    display: flex;
    justify-content: space-between;

    .meta-info > .author {
      display: flex;
      flex-direction: row;
      width: 280px;
      padding: 0.8pc;
      background-color: #EEEEEE;
      border-radius: 20px;

      img {
        width: 50px;
        border-radius: 50%;
      }
      .info{
        margin-left: 1pc;
        > span {
          font-size: 1.2rem;
          color: grey;
          line-height: 2rem;
        }
      }
    }

    .meta-item-details {
      width: 280px;
      margin-top: 2pc;
      background-color: #EEEEEE;
      border-radius: 20px;
      padding: 1pc;

      .list-details > li {
        display: flex;
        font-size: 1.5rem;
        line-height: 4rem;
      }
    }

    .action_buttons {
      display: flex;
      flex-direction: column;
      margin-top: 2pc;

      button {
        &:hover {
          background-color: #fff;
          color: #097292;
        }
      }
    }

    .bottom_container {
      width: 100%;
      display: flex;
      margin: 4pc 0;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        margin: 1pc;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 1.5pc;
        background-color: #EEEEEE;
        text-align: center;
      }
    }
  }


  @media (max-width: 1000px) {
    .body_container_top {
      flex-direction: column !important;
      align-items: center !important;

    }
  }
  
`;
