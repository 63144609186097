import React from "react";
import styled from "styled-components";
import background from "../../assets/creditnature/images/jordan-heath-4z_SYrG5mgA-unsplash.jpg";

const CreditNatureBanner = () => {
  return (
    <BannerStyled style={{ backgroundImage: `url(${background})` }}>
      <section>
        <h1>CreditNature tokens.</h1>
      </section>
    </BannerStyled>
  );
};

export default CreditNatureBanner;

const BannerStyled = styled.div`
  height: 30vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;

  h5 {
    font-family: "Poppins", sans-serif;
    font-size: 3.5rem;
  }
`;
