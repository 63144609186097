import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logodark from "../../assets/images/logo/logo_dark.png";
import logofooter from "../../assets/images/logo/logo2.png";
import styled from "styled-components";
const Footer = () => {
  const accountList = [
    // {
    //   title: "Authors",
    //   link: "/authors",
    // },
    // {
    //   title: "Collection",
    //   link: "/wallet-connect",
    // },
    // {
    //   title: "Author Profile",
    //   link: "/edit-profile",
    // },
    // {
    //   title: "Create Item",
    //   link: "/create-item",
    // },
  ];
  const resourcesList = [
    // {
    //   title: "Help & Support",
    //   link: "/help-center",
    // },
    // // {
    // //   title: "Live Auctions",
    // //   link: "/live-auctions",
    // // },
    // {
    //   title: "Item Details",
    //   link: "/item-details",
    // },
    // {
    //   title: "Activity",
    //   link: "/activity",
    // },
  ];
  const companyList = [
    {
      title: "Explore",
      link: "/explore",
    },
    {
      title: "Contact Us",
      link: "/contact",
    },
    // {
    //   title: "Our Blog",
    //   link: "https://elevate.ca/blog/",
    // },
    // {
    //   title: "FAQ",
    //   link: "/faq",
    // },
  ];
  const socialList = [
    {
      icon: "fab fa-twitter",
      link: "https://twitter.com/credit_nature_",
    },
    // {
    //   icon: "fab fa-instagram",
    //   link: "https://www.instagram.com/ElevateTechCA",
    // },
    // {
    //   icon: "fab fa-facebook",
    //   link: "https://www.facebook.com/ElevateTechCA",
    // },
    {
      icon: "fab fa-linkedin",
      link: "https://www.linkedin.com/showcase/creditnature/",
    },
    // {
    //   icon: "fab fa-youtube",
    //   link: "https://www.youtube.com/c/ElevateTechCA",
    // },
  ];

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <FooterStyled>
      <section>
        <div className="logo-footer" id="logo-footer">
          <Link to="/">
            <img
              width={"300px"}
              className="logo-light"
              id="logo_footer"
              src={logodark}
              alt="nft-gaming"
            />
          </Link>
        </div>
      </section>

      <section>
        <div className="widget-social style-1 mg-t32">
          <ul>
            {socialList.map((item, index) => (
              <li key={index}>
                <a className="social-icon" target="_blank" href={item.link}>
                  <i className={item.icon}></i>
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <p>PRIVACY POLICY</p>
          <p>COMPANY NUMBER: 13755375</p>
          <p>© CreditNature2022</p>
        </div>
      </section>
      {/* {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>} */}
    </FooterStyled>
  );
};

export default Footer;

const FooterStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 100px;
  background-color: #093842;
  font-family: "Poppins", sans-serif;
  img {
    object-fit: contain;
    width: 250px;
  }

  > section {
    :last-child {
      align-items: flex-end;
    }
    .social-icon {
      border-radius: 50% !important;
      background-color: #d7a23f;
      color: #093842;
    }
    .widget-social {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 10px;
    }
    p {
      text-align: end;
      color: white;
      font-size: medium;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    > section {
      :last-child {
        align-items: center;
      }
      .widget-social {
        align-items: center
      }
      p {
        text-align: center;
      }
    }
  }
`;
