import React, { useState, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardModal from "../CardModal";
import { NFTService } from "../../../services/nft.service";
import { selectUser } from "../../../redux/reducers/userReducer.ts";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ReactPlayer from "react-player";

const TodayPicks = (props) => {
  const data = props.data;
  const user = useSelector(selectUser);
  const nftService = useMemo(() => new NFTService(), []);
  const [visible, setVisible] = useState(8);
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const defaultUserProfileImage = "https://niftron.infura-ipfs.io/ipfs/QmcFee3QWZLin6tkHJvDC2o3pXQcSnoJHWDX3k3S5a43tQ";
  const adminAddress = "0x89eaaf9064d3dd94990b9c571383e9c4d2f295de";

  const buyItem = async () => {
    props.setTriggerFixedRefetch(false);
    await nftService.buyNFT(selectedItem, selectedItem._id, user.address);
    props.setTriggerFixedRefetch(true);
    setModalShow(false);
  };

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  const FileContent = ({ nft }) =>
    !nft.isFileVideo ? (
      <img src={nft.file} alt="Axies" style={{"minHeight":"600px"}}/>
    ) : (
      <ReactPlayer height="200px" width="100%" controls playing loop url={nft.file} />
    );

  return (
    <Fragment>
      <section className="tf-section today-pick">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-live-auctions mg-bt-21">
                <h2 className="tf-title pad-l-7">{props.title || 'Tokens On Sale'}</h2>
                {/* <Link to="/explore" className="exp style2">
                  EXPLORE MORE
                </Link> */}
              </div>
            </div>
            {data.length === 0 && (
              <NotFoundStyledContainer>
                <h4>No items found</h4>
              </NotFoundStyledContainer>
            )}
            {data.slice(0, visible).map((item, index) => (
              <div
                key={index}
                className="fl-item col-xl-12 col-lg-12 col-md-12 col-sm-12"
              >
                <div style={{"minHeight":"600px"}}
                  className={`sc-card-product menu_card style2 ${item.feature ? "comingsoon" : ""
                    } `}
                >
                  <div className="meta-info style">
                    <div className="author">
                      <div className="avatar">
                        <img src={item.collectionDetails.logo} alt="Axies" />
                      </div>
                      <div className="info">
                        <span>Collection</span>
                        <h6>
                          {" "}
                          <Link to={`/collection/${item.collectionDetails._id}`}>
                            {item.collectionDetails.name}
                          </Link>{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="menu_card">
                      <div className="price">
                        <h5>{item?.attributes[0]?.value}</h5>
                      </div>

                    </div>
                    {/* <div className="menu_card">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <i className="fas fa-ellipsis-h"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ margin: 0 }}>
                          <Dropdown.Item href="#">
                            Refresh Metadata
                          </Dropdown.Item>
                          <Dropdown.Item href="#">Share</Dropdown.Item>
                          <Dropdown.Item href="#">Report</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
  */}
                  </div>
                  <div className="card-media" style={{"minHeight":"600px"}}>
                    <Link to={`/item-details/${item._id}`}>
                      <FileContent nft={item} />
                    </Link>
                    {/* <Link to="/wallet-connect" className="wishlist-button heart">
                      <span className="number-like">{item.wishlist}</span>
                    </Link> */}
                    {/* <div className="coming-soon">{item.feature}</div> */}
                  </div>
                  <div className="card-title">
                    <h5 className="style2">
                      <Link to={`/item-details/${item._id}`}>"{item.title}"</Link>
                    </h5>
                    {/* <div className="tags">{item.tags}</div> */}
                  </div>
                  <div className="meta-info">
                    <div className="author">
                      <div className="avatar">
                        <img src={item.creatorDetails?.profileImage || defaultUserProfileImage} alt="Axies" />
                      </div>
                      <div className="info">
                        <span>Creator</span>
                        <h6>
                          {" "}
                          <Link to={`/profile/${item.creatorDetails?.address}`}>
                            {item?.creatorDetails?.displayName ||
                              `${item.creator?.toLowerCase().slice(
                                0,
                                15
                              )}...`}
                          </Link>{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="price">
                      <span>Price</span>
                      <h5>{item.price} USD</h5>
                    </div>
                  </div>
                  {user.address !== null && user.address !== adminAddress && user.address !== item?.creatorPublicKey && (
                    <div className="card-bottom">
                      <button
                        onClick={() => {
                          setSelectedItem(item);
                          setModalShow(true);
                        }}
                        className="sc-button style bag fl-button pri-3 no-bg"
                      >
                        <span>Buy Now</span>
                      </button>
                      <Link to="/activity" className="view-history reload">
                        View History
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {visible < data.length && (
              <div className="col-md-12 wrap-inner load-more text-center">
                <Link
                  to="#"
                  id="load-more"
                  className="sc-button loadmore fl-button pri-3"
                  onClick={showMoreItems}
                >
                  <span>Load More</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
      {selectedItem && (
        <CardModal action={buyItem} selectedItem={selectedItem} show={modalShow} onHide={() => setModalShow(false)} />
      )}
    </Fragment>
  );
};

TodayPicks.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TodayPicks;

const NotFoundStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex: 1;
  border: 1px solid;
  border-radius: 10px;
  margin-bottom: 20px;

  h4 { 
    padding: 1rem;
  }

  p {
    color: grey;
    padding: 1rem;
    font-size: 1.6rem;
  }

  button {
    margin: 1rem;
    padding: 1.5rem 2rem;

    &:hover {
      color: #fff;
      background-color: #4032ff;
    }
  }
`;
