export const tokenExtraDetails = {
  pricePerToken: '$4,854',
  noAvailable: 1030,
  noTotal: 1030,
  descriptions: [
    {
      title: 'About the rewilding plans at Newtondale',
      points: [
        'Areas of clear-fell, thinning, tree veteranisation and forest buffers',
        'Deer population management',
        'Leave carrion to lie',
        'Grey Squirrel control',
        'Introduction of primitive pigs, cattle and ponies',
        'Wildflower seeding'
      ]
    },
    {
      title: 'Forecast impact',
      points: [
        '15% increase in Ecosystem Integrity',
        'Shift into land management which has a positive impact on nature.'
      ]
    },
    {
      title: 'Token buyer privileges',
      points: [
        'First access to nature positive carbon from site',
        'Site visits',
        'Access to dashboard to assess impact of investment',
      ],
    }
  ],
};


export const tokenExtraDetailsOld = {
  pricePerToken: '$4155',
  noAvailable: 1030,
  noTotal: 1030,
  descriptions: [
    {
      title: 'Bolt-ons',
      points: [
        'Project acceleration $500k minimum',
        'Outcome gurantee $500k minimum',
      ]
    },
    {
      title: 'Forecast impact',
      points: [
        '30% ecosystem integrity uplist over 5 years',
        'Ecosystem management rating from D to B',
        'Gold standard data'
      ]
    },
    {
      title: 'Token buyer privileges',
      points: [
        'Right to report and display impact of investment',
        'Exclusive site access',
        'Discounted activities on site',
        'Storified reports',
        'First access to carbon credits produced on site',
      ],
    }
  ],
};
