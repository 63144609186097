import React, { useEffect, useMemo, useState } from "react";
import Footer from "../components/footer/Footer";
import MainBanner from "../components/banner/MainBanner";
import LiveAuction from "../components/layouts/home/LiveAuction";
import TopSeller from "../components/layouts/home/TopSeller";
import TodayPicks from "../components/layouts/home/TodayPicks";
import PopularCollection from "../components/layouts/PopularCollection";
import Create from "../components/layouts/Create";
import Header from "../components/header/Header";
import { NFTService } from "../services/nft.service";
import { CollectionService } from "../services/collections.service";
import { Box, CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import _ from "lodash";
import { getTopSellers } from "../services/user.service.ts";
import heroSliderData from '../assets/fake-data/data-slider-3';
import Slider from '../components/slider/Slider';
import { useNavigate } from "react-router-dom";
import DetailBanner from "../components/banner/DetailBanner";
import CreditNatureBanner from "../components/banner/CreditNatureBanner";
import ProjectsBanner from "../components/banner/ProjectsBanner";
import PartnersBanner from "../components/banner/PartnersBanner";
import InqurieForm from "../components/InqurieForm/InqurieForm";
import InvestorBenefits from "../components/InvestorBenefits/InvestorBenefits";
import CreditNatureTokens from "../components/CreditNatureTokens/CreditNatureTokens";
import Projects from "../components/Projects/Projects";
import MoreProjects from "../components/ProjectDetails/MoreProjects";

const box1 = {
  title: "Rewilding Impact Tokens",
  description: "Invest in a stake in a rewilding project"
};

const box2 = {
  title: "Wildlife Impact Tokens",
  comingSoon: true,
  description: "Invest in a stake in a species repopulation project"
};

const HomePage = () => {
  const collectionsService = useMemo(() => new CollectionService(), []);
  const nftService = useMemo(() => new NFTService(), []);
  const [popularCollections, setPopularCollections] = useState();
  const [uniqueNfts, setUniqueNfts] = useState();
  // const [liveAuctions, setLiveAuctions] = useState();
  const [todaysPicks, setTodaysPicks] = useState();
  const [topSellers, setTopSellers] = useState();

  // const [triggerTimeRefetch, setTriggerTimeRefetch] = useState(false);
  const [triggerFixedRefetch, setTriggerFixedRefetch] = useState(false);

  useEffect(() => {
    const getInitialData = async () => {
      const lData = (await getTopSellers()).topSellers;
      setTopSellers(lData);
    };

    getInitialData();
  }, []);

  useEffect(() => {
    const getInitialData = async () => {
      const lData = (await nftService.getNFTs("true")).nfts;
      // setUniqueNfts(_.uniqBy(lData, 'category'));
      setUniqueNfts(lData);
    };

    getInitialData();
  }, [nftService]);

  // useEffect(() => {
  //   const getInitialData = async () => {
  //     const lData = (await nftService.getNFTsOfType('Time Auctions')).nfts;
  //     setLiveAuctions(lData);
  //   };

  //   getInitialData();
  // }, [nftService, triggerTimeRefetch]);

  useEffect(() => {
    const getInitialData = async () => {
      // const tData = (await nftService.getNFTsOfType('Fixed Price')).nfts;

      const tData = (await nftService.getNFTs("true")).nfts;
      setTodaysPicks([tData.find(nft => nft.title === 'Newtondale')]);
    };

    getInitialData();
  }, [nftService, triggerFixedRefetch]);

  useEffect(() => {
    const getInitialData = async () => {
      const pData = (await collectionsService.getPopularCollections()).collections;
      setPopularCollections(pData);
    };

    getInitialData();
  }, [collectionsService]);

  return (
    <div className="home-4">
      <Header />
      {/* <Slider data={heroSliderData} /> */}
      <MainBanner />
      <DetailBanner />
      <CreditNatureBanner />
      <CreditNatureTokens todaysPicks={todaysPicks} />
      <ProjectsBanner />
      <Projects />
      {/* <MoreProjects /> */}
      <InvestorBenefits />
      <InqurieForm />
      <PartnersBanner />
      {todaysPicks && popularCollections && uniqueNfts && topSellers ? (
        <>
          {/* {liveAuctions && todaysPicks && popularCollections && uniqueNfts && topSellers ? ( */}
          {/* <Banner nfts={uniqueNfts} /> */}
          {/* <LiveAuction setTriggerTimeRefetch={setTriggerTimeRefetch} data={liveAuctions} /> */}
          {/* <TopSeller data={topSellers} /> */}
          {/* <Boxes>
            <BoxContainer className="flex themesflat-container">
              <StyledBox
                onClick={() => navigate(`/collection/${todaysPicks[0].collectionDetails._id}`)}
                sx={{ cursor: 'pointer', marginRight: '4rem' }}
              >
                <h2 style={{ marginBottom: '1rem' }}>{box1.title}</h2>
                <p>{box1.description}</p>
              </StyledBox>
              <StyledBox sx={{ position: 'relative' }}>
                <h2 style={{ marginBottom: '1rem' }}>{box2.title}</h2>
                <p>{box2.description}</p>
                {box2.comingSoon && (
                  <ComingSoon>
                    <h1>Coming Soon</h1>
                  </ComingSoon>
                )}
              </StyledBox>
            </BoxContainer>
          </Boxes> */}
          {/* <TodayPicks
            title="Our Projects"
            setTriggerFixedRefetch={setTriggerFixedRefetch}
            data={todaysPicks}
          /> */}
          {/* <PopularCollection data={popularCollections} /> */}
        </>
      ) : (
        <ProgressContainer>
          <CircularProgress color="primary" />
        </ProgressContainer>
      )
      }
      {/* <Create /> */}
      <Footer />
    </div >
  );
};

export default HomePage;

const ProgressContainer = styled.div`
  display: flex;
  height: 350px;
  align-items: center;
  justify-content: center;
  background-color: #14141F;

  > * {
    color: #FFF !important;
  }
`;


const BoxContainer = styled.div`
  justify-content: space-around;
  height: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;

    > div {
      width: 100%;

      &:first-child {
        margin-bottom: 2rem;
      }
    }
  }
`;

const ComingSoon = styled.div`
  position: absolute;
  background: grey;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  > h1 {
    color: black;
    transform: rotate(-45deg);
  }
`;

const Boxes = styled.div`
  background: var(--bg-section);
  height: 400px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 100vh;
  }
`;

const StyledBox = styled(Box)`
  flex : 1;
  padding: 5;
  margin-right: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #549E86;
  border-radius: 20px;
`;
