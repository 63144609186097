import React from 'react'
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

function FeaturedProject({ item }) {
  const navigate = useNavigate();

  return (
    <Container>
      <Left style={{ backgroundImage: `url(${item.img})` }}>
        <p>{item.title}</p>
        <h1>{item.heading}</h1>
        <img src={item.logo} alt="logo" />
      </Left>
      <Right color={item.color}>
        <h5>{item.paraHeader}</h5>
        <h5>{item.paraHeader1}</h5>
        <p>{item.para}</p>
        <button onClick={() => navigate(`/item-details/634702e7525957045b2b4668`)}>VIEW PROJECT →</button>
      </Right>
    </Container>
  )
}

export default FeaturedProject;

const Container = styled.div`
  display: flex;
  height: 50vh;

  > div {
    flex: 1;
  }

  &:first-child {
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const Left = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 4vw;

  > p,
  > h1 {
    color: white !important;
    font-family: "Poppins", sans-serif;
    text-align: left !important;
  }

  > p {
    font-size: 1.5rem;
    font-weight: bold;
    opacity: 0.6;
    letter-spacing: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  > h1 {
    margin-bottom: 2rem;
  }
`;

const Right = styled.div`
  background-color: ${props => props.color};
  padding: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    margin-top: 4rem;
    background-color: white;
    font-weight: bold !important;
    letter-spacing: 0.3rem;
    color: #093842;
    border-radius: 0;
    width: 60%;
  }

  h5,
  p {
    color: black !important;
    font-family: "Poppins", sans-serif;
    text-align: left !important;
  }

  @media screen and (max-width: 768px) {
    h5,
    p {
      margin: 1rem 0;
    }

    button {
      width: 100%;
    }
  }
`;
