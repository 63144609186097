import React, { useState, Fragment, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NFTService } from "../../../services/nft.service";
import { selectUser } from "../../../redux/reducers/userReducer.ts";
import CardModal from "../CardModal";
import styled from "styled-components";
import ReactPlayer from "react-player";

const ExploreItem = (props) => {
  const user = useSelector(selectUser);
  const nftService = useMemo(() => new NFTService(), []);
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const defaultUserProfileImage = "https://niftron.infura-ipfs.io/ipfs/QmcFee3QWZLin6tkHJvDC2o3pXQcSnoJHWDX3k3S5a43tQ";
  const adminAddress = "0x89eaaf9064d3dd94990b9c571383e9c4d2f295de";

  const bidOnItem = async (amount) => {
    props.setTriggerTimeRefetch(false);
    await nftService.bidOnNFT(selectedItem, selectedItem._id, amount, user.address);
    props.setTriggerTimeRefetch(true);
    setModalShow(false);
  };

  const buyItem = async () => {
    props.setTriggerFixedRefetch(false);
    await nftService.buyNFT(selectedItem, selectedItem._id, user.address);
    props.setTriggerFixedRefetch(true);
    setModalShow(false);
  };

  const showMoreItems = () => {
    props.setVisible((prevValue) => prevValue + 4);
  };

  const FileContent = ({ nft }) =>
    !nft.isFileVideo ? (
      <img src={nft.file} alt="Axies" />
    ) : (
      <ReactPlayer height="200px" width="100%" controls playing loop url={nft.file} />
    );

  return (
    <Fragment>
      <div className="explore">
        <div className="box-epxlore">
          {props.displayingNfts.length === 0 && (
            <NotFoundStyledContainer>
              <h4>No items found</h4>
            </NotFoundStyledContainer>
          )}
          {props.displayingNfts.map((item, index) => (
            <div
              className={`sc-card-product explode style2 mg-bt ${item.method === 'Time Auctions' && new Date(item.startingDate) > Date.now() ? "comingsoon" : ""
                } `}
              key={index}
            >
              <div className="meta-info style">
                <div className="author">
                  <div className="avatar">
                    <img src={item.collectionDetails.logo} alt="Axies" />
                  </div>
                  <div className="info">
                    <span>Collection</span>
                    <h6>
                      {" "}
                      <Link to={`/collection/${item.collectionDetails._id}`}>
                        {item.collectionDetails.name}
                      </Link>{" "}
                    </h6>
                  </div>
                </div>
                <div className="menu_card">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="fas fa-ellipsis-h"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ margin: 0 }}>
                      <Dropdown.Item href="#">
                        Refresh Metadata
                      </Dropdown.Item>
                      <Dropdown.Item href="#">Share</Dropdown.Item>
                      <Dropdown.Item href="#">Report</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="card-media">
                <Link to={`/item-details/${item._id}`}>
                  <FileContent nft={item} />
                </Link>
                {/* <Link to="/wallet-connect" className="wishlist-button heart">
                  <span className="number-like">{item.wishlist}</span>
                </Link> */}
                {item.method === 'Time Auctions' && (
                  <div className="featured-countdown">
                    <span className="slogan"></span>
                    <Countdown date={new Date(item.startingDate).getTime()}>
                      {(Date.now() > new Date(item.endingDate).getTime()) ? (
                        <span>Auction Item Expired</span>
                      ) : (
                        <span>You are good to go!</span>
                      )}
                    </Countdown>
                  </div>
                )}
              </div>
              <div className="card-title">
                <h5>
                  <Link to={`/item-details/${item._id}`}>"{item.title}"</Link>
                </h5>
              </div>
              <div className="meta-info">
                <div className="author">
                  <div className="avatar">
                    <img src={item.creatorDetails?.profileImage || defaultUserProfileImage} alt="Axies" />
                  </div>
                  <div className="info">
                    <span>Creator</span>
                    <h6>
                      {" "}
                      <Link to={`/profile/${item.creatorDetails?.address}`}>
                        {item?.creatorDetails?.displayName ||
                          `${item.creator?.toLowerCase().slice(
                            0,
                            15
                          )}...`}
                      </Link>{" "}
                    </h6>
                  </div>
                </div>
                <div className="price">
                  {item.method === 'Time Auctions' ? <span>Current Bid</span> : <span>Price</span>}
                  <h5>{item.price || item.currentBid} ETH</h5>
                </div>
              </div>
              {user.address !== null && user.address !== adminAddress && user.address !== item?.creatorPublicKey && (
                item.method === 'Fixed Price' ? (
                  <div className="card-bottom" style={{ border: 'none' }}>
                    <button
                      onClick={() => {
                        setSelectedItem(item);
                        setModalShow(true);
                      }}
                      className="sc-button style bag fl-button pri-3 no-bg"
                    >
                      <span>Buy Now</span>
                    </button>
                    <Link to="/activity" className="view-history reload">
                      View History
                    </Link>
                  </div>
                ) : (
                  new Date(item.startingDate).getTime() < Date.now() ? (
                    Date.now() < new Date(item.endingDate).getTime() ? (
                      <div className="card-bottom" style={{ border: 'none' }}>
                        <button
                          onClick={() => {
                            setSelectedItem(item);
                            setModalShow(true);
                          }}
                          className="sc-button style bag fl-button pri-3 no-bg"
                        >
                          <span>Place Bid</span>
                        </button>
                        <Link to="/activity" className="view-history reload">
                          View History
                        </Link>
                      </div>
                    ) : (
                      <div className="card-bottom" style={{ justifyContent: 'center', border: 'none' }}>
                        <p style={{ textAlign: 'center' }}>This item is accepting no more bids</p>
                      </div>
                    )
                  ) : (
                    <div className="card-bottom" style={{ justifyContent: 'center', border: 'none' }}>
                      <p style={{ textAlign: 'center' }}>This item will be available soon!</p>
                    </div>
                  )
                ))}
            </div>
          ))}
        </div>
        {props.displayingNfts.length !== 0 && (
          <div className="btn-auction center">
            <Link
              to="#"
              id="load-more"
              className="sc-button loadmore fl-button pri-3"
              onClick={showMoreItems}
            >
              <span>Load More</span>
            </Link>
          </div>
        )}
        {selectedItem && (
          <CardModal
            action={() => selectedItem.type === 'Time Auctions' ? bidOnItem() : buyItem()}
            selectedItem={selectedItem}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        )}
      </div>
    </Fragment >
  );
};

export default ExploreItem;

const NotFoundStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex: 1;
  border: 1px solid;
  border-radius: 10px;
  margin-bottom: 20px;

  h4 { 
    padding: 1rem;
  }

  p {
    color: grey;
    padding: 1rem;
    font-size: 1.6rem;
  }

  button {
    margin: 1rem;
    padding: 1.5rem 2rem;

    &:hover {
      color: #fff;
      background-color: #4032ff;
    }
  }
`;
