import styled from 'styled-components'
import ProjectCard from './ProjectCard'
import projectImageTwo from "../../assets/creditnature/images/tree-stump.jpeg";
import projectImageThree from "../../assets/creditnature/images/mountains.jpeg";

const MoreProjects = () => {
	return (
		<StyledContainer>
			<h2>MORE PROJECTS</h2>
			<section>
				<ProjectCard title="Broughton Hall" size="1030ha" edition="Reset" image={projectImageThree} />
				<ProjectCard title="Clay's Lake Habitat Creation" size="2080ha" edition="Reset" image={projectImageTwo} />
				<ProjectCard title="Broughton Hall" size="13140ha" edition="Reset" image={projectImageThree} />
			</section>
		</StyledContainer>
	)
}

export default MoreProjects

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	margin-top: 2rem;
	font-family: 'Poppins', sans-serif;

	> h2 {
		font-weight: bold;
		font-size: medium;
		letter-spacing: 0.3rem;
		color: grey;
	}

	> section {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	@media screen and (max-width: 768px) {
    > section {
			justify-content: center;
		}
  }
`;