import React, { useState, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import Countdown from "react-countdown";
import CardModal from "../CardModal";

import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import { NFTService } from "../../../services/nft.service";
import { selectUser } from "../../../redux/reducers/userReducer.ts";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ReactPlayer from "react-player";

const LiveAuction = (props) => {
  const data = props.data;
  const user = useSelector(selectUser);
  const nftService = useMemo(() => new NFTService(), []);
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const adminAddress = "0x89eaaf9064d3dd94990b9c571383e9c4d2f295de";

  const bidOnItem = async (amount) => {
    props.setTriggerTimeRefetch(false);
    await nftService.bidOnNFT(
      selectedItem,
      selectedItem._id,
      amount,
      user.address
    );
    props.setTriggerTimeRefetch(true);
    setModalShow(false);
  };

  const FileContent = ({ nft }) =>
    !nft.isFileVideo ? (
      <img src={nft.file} alt="Axies" />
    ) : (
      <ReactPlayer height="200px" width="100%" controls playing loop url={nft.file} />
    );

  return (
    <Fragment>
      <section className="tf-section live-auctions">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-live-auctions">
                <h2 className="tf-title pb-20">Other Live Auctions</h2>
                <Link to="/explore" className="exp style2">
                  EXPLORE MORE
                </Link>
              </div>
            </div>
            {data.length === 0 && (
              <NotFoundStyledContainer>
                <h4>No items found</h4>
              </NotFoundStyledContainer>
            )}
            <div className="col-md-12">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={30}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  767: {
                    slidesPerView: 2,
                  },
                  991: {
                    slidesPerView: 3,
                  },
                  1300: {
                    slidesPerView: 4,
                  },
                }}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
              >
                {data.slice(0, 7).map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="swiper-container show-shadow carousel auctions">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="slider-item">
                            <div className="sc-card-product menu_card">
                              <div className="meta-info style">
                                <div className="author">
                                  <div className="avatar">
                                    <img
                                      src={item.collectionDetails.logo}
                                      alt="Axies"
                                    />
                                  </div>
                                  <div className="info">
                                    <span>Collection</span>
                                    <h6>
                                      {" "}
                                      <Link
                                        to={`/collection/${item.collectionDetails._id}`}
                                      >
                                        {item.collectionDetails.name}
                                      </Link>{" "}
                                    </h6>
                                  </div>
                                </div>
                                <div className="menu_card">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <i className="fas fa-ellipsis-h"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ margin: 0 }}>
                                      <Dropdown.Item href="#">
                                        Refresh Metadata
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#">
                                        Share
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#">
                                        Report
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="card-media">
                                <Link to={`/item-details/${item._id}`}>
                                  <FileContent nft={item} />
                                </Link>
                                {/* <Link
                                  to="/wallet-connect"
                                  className="wishlist-button heart"
                                >
                                  <span className="number-like">
                                    {" "}
                                    {item.wishlist}
                                  </span>
                                </Link> */}
                                <div className="featured-countdown">
                                  <span className="slogan"></span>
                                  <Countdown
                                    date={new Date(item.startingDate).getTime()}
                                  >
                                    {Date.now() >
                                    new Date(item.endingDate).getTime() ? (
                                      <span>Auction Item Expired</span>
                                    ) : (
                                      <span>You are good to go!</span>
                                    )}
                                  </Countdown>
                                </div>
                              </div>
                              <div className="card-title">
                                <h5>
                                  <Link to={`/item-details/${item._id}`}>
                                    "{item.title} "
                                  </Link>
                                </h5>
                                {/* <div className="tags">{item.tags}</div> */}
                              </div>
                              <div className="meta-info">
                                <div className="author">
                                  <div className="avatar">
                                    <img
                                      src={item.creatorDetails?.profileImage}
                                      alt="Axies"
                                    />
                                  </div>
                                  <div className="info">
                                    <span>Creator</span>
                                    <h6>
                                      {" "}
                                      <Link
                                        to={`/profile/${item.creatorDetails?.address}`}
                                      >
                                        {item.creatorDetails?.displayName}
                                      </Link>{" "}
                                    </h6>
                                  </div>
                                </div>
                                <div className="price">
                                  <span>Current Bid</span>
                                  <h5>{item.currentBid} ETH</h5>
                                </div>
                              </div>
                              {user.address !== null && user.address !== adminAddress && user.address !== item?.creatorPublicKey &&
                                (new Date(item.startingDate).getTime() <
                                Date.now() ? (
                                  Date.now() <
                                  new Date(item.endingDate).getTime() ? (
                                    <div className="card-bottom">
                                      <button
                                        onClick={() => {
                                          setSelectedItem(item);
                                          setModalShow(true);
                                        }}
                                        className="sc-button style bag fl-button pri-3 no-bg"
                                      >
                                        <span>Place Bid</span>
                                      </button>
                                      <Link
                                        to="/activity"
                                        className="view-history reload"
                                      >
                                        View History
                                      </Link>
                                    </div>
                                  ) : (
                                    <div
                                      className="card-bottom"
                                      style={{
                                        justifyContent: "center",
                                        marginTop: "40px",
                                      }}
                                    >
                                      <p style={{ textAlign: "center" }}>
                                        This item is accepting no more bids
                                      </p>
                                    </div>
                                  )
                                ) : (
                                  <div
                                    className="card-bottom"
                                    style={{
                                      justifyContent: "center",
                                      marginTop: "40px",
                                    }}
                                  >
                                    <p style={{ textAlign: "center" }}>
                                      This item will be available soon!
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {selectedItem && (
        <CardModal
          action={bidOnItem}
          selectedItem={selectedItem}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </Fragment>
  );
};

LiveAuction.propTypes = {
  data: PropTypes.array.isRequired,
};

export default LiveAuction;

const NotFoundStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex: 1;
  border: 1px solid;
  border-radius: 10px;
  margin-bottom: 20px;

  h4 {
    padding: 1rem;
  }

  p {
    color: grey;
    padding: 1rem;
    font-size: 1.6rem;
  }

  button {
    margin: 1rem;
    padding: 1.5rem 2rem;

    &:hover {
      color: #fff;
      background-color: #4032ff;
    }
  }
`;
