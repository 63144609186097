import React from "react";
import styled from "styled-components";
import partnerOne from "../../assets/creditnature/images/partners/one.png";
import partnerTwo from "../../assets/creditnature/images/partners/two.png";
import partnerThree from "../../assets/creditnature/images/partners/three.png";
import partnerFour from "../../assets/creditnature/images/partners/four.png";
import partnerFive from "../../assets/creditnature/images/partners/five.png";

const PartnersBanner = () => {
  return (
    <BannerStyled>
      <section>
        <h5>Partners</h5>

        <container>
          <img src={partnerOne} alt="partner" />
          <img src={partnerTwo} alt="partner" />
          <img src={partnerThree} alt="partner" />
          <img src={partnerFour} alt="partner" />
          <img src={partnerFive} alt="partner" />
        </container>
      </section>
    </BannerStyled>
  );
};

export default PartnersBanner;

const BannerStyled = styled.div`
  height: 40vh;
  display: grid;
  place-items: center;
  background: #c6d0d6;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    object-fit: contain;
    width: 200px;
    margin: 0 2rem;
    filter: grayscale(100%);
  }

  h5 {
    font-size: 4rem;
    margin-bottom: 5rem;
  }

  @media screen and (max-width: 768px) {
    height: auto;

    h5 {
      margin-top: 5rem;
    }

    container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;
