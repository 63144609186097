import React from 'react'
import styled from 'styled-components';
import img from "../../assets/creditnature/images/rit.png";
import img1 from "../../assets/creditnature/images/positive-nature-recovery-ed1.jpg";
import Token from './Token';

const data = [
  {
    img,
    title: 'NATURE IMPACT TOKENS',
    heading: 'Wildlife Impact Tokens.',
    paraHeader: 'A fractional stake in a wildlife project.',
    para: 'Impact of your investment is forecasted, tracked and evidenced by the NARIA framework rating and indexes.',
    color: '#d7a23f',
  },
  {
    img: img1,
    title: 'NATURE IMPACT TOKENS',
    heading: 'Rewilding Impact Tokens.',
    paraHeader: 'A fractional stake in a rewidling project.',
    para: 'Impact of your investment is forecasted, tracked and evidenced by the NARIA framework rating and indexes.',
    color: '#093842',
  }
]

function CreditNatureTokens(props) {
  return (
    <Container>
      {data.map((item, index) => (
        <Token
          item={item}
          key={index}
          todaysPicks={props.todaysPicks}
        />
      ))}
    </Container>
  )
}

export default CreditNatureTokens;

const Container = styled.div`
  padding: 2vw 10vw;
`;
