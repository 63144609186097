import React from 'react'
import styled from 'styled-components';
import FeaturedProject from './FeaturedProject';
import img from "../../assets/creditnature/images/newtondale.png";
import logo from "../../assets/creditnature/images/investor_benefit_4-removebg-preview.png";

const data = [
  {
    img,
    logo,
    title: 'PROJECT',
    heading: 'Newtondale.',
    paraHeader: 'Size | 1030ha',
    paraHeader1: 'Edition | Reset',
    para: 'Brief description of this project.',
    color: '#c6d0d6',
  },
]

function Projects() {
  return (
    <Container>
      <p>FEATURED PROJECT</p>
      {data.map((item, index) => (
        <FeaturedProject item={item} key={index} />
      ))}
    </Container>
  )
}

export default Projects;

const Container = styled.div`
  padding: 2vw 10vw;

  > p {
    color: #c6d0d6 !important;
    text-align: center;
    font-family: "Poppins", sans-serif;

    &:first-child {
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 0.5rem;
      margin-bottom: 4rem;
    }

    &:nth-child(3) {
      font-size: 1.5rem;
      font-weight: bold;
      opacity: 0.6;
      letter-spacing: 0.5rem;
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    > p {
      margin-top: 2rem;
    }
  }
`;
