import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { create } from "ipfs-http-client";
import Switch from "react-switch";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../assets/images/box-item/image-box-6.jpg";
import { CollectionService } from "../services/collections.service";
import { NFTService } from "../services/nft.service";
import { IPFS } from "../apis/apis.ts";
import {
  selectUser,
  selectUserDetails,
} from "../redux/reducers/userReducer.ts";
import { authGuard, nftCategories } from "../utils/utils.ts";
import CreateCollection from "../components/createcollection/CreateCollection";
import truncate from "truncate";
import Web3 from "web3";

const client = create(IPFS);

const CommonFields = ({
  title,
  setTitle,
  description,
  setDescription,
  royalties,
  setRoyalties,
  metadataTitles,
  setMetadataTitles,
  metadataFeilds,
  setMetadataFeilds,
  allCollections,
  selectedCollection,
  setSelectedCollection,
  showCreateCollection,
  setShowCreateCollection,
}) => (
  <>
    <h4 className="title-create-item">Name *</h4>
    <input
      value={title}
      required
      onChange={(e) => setTitle(e.target.value)}
      type="text"
      placeholder="Item Name"
    />

    <h4 className="title-create-item">Description *</h4>
    <textarea
      value={description}
      required
      onChange={(e) => setDescription(e.target.value)}
      placeholder="e.g. “This is very limited item”"
    ></textarea>

    <div className="row-form style-3">
      <div className="inner-row-form">
        <h4 className="title-create-item">Royalty Percentage *</h4>
        <input
          value={royalties}
          onChange={(e) => setRoyalties(e.target.value)}
          type="number"
          placeholder="10"
          min={0}
        />
      </div>
    </div>

    <h4 style={{ marginTop: "14px" }} className="title-create-item">
      Attributes Titles
    </h4>
    <textarea
      value={metadataTitles}
      onChange={(e) => setMetadataTitles(e.target.value)}
      placeholder="e.g. Author, Location"
    ></textarea>

    <h4 className="title-create-item">Attributes Fields</h4>
    <textarea
      value={metadataFeilds}
      onChange={(e) => setMetadataFeilds(e.target.value)}
      placeholder="e.g. Rajapaksha, Pakistan"
    ></textarea>
  </>
);

const CreateItem = () => {
  const user = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);
  const isUserLoggedIn = user.address !== null;
  const navigate = useNavigate();

  const [file, setFile] = useState();
  const [fileLoading, setFileLoading] = useState(false);

  const [isFileVideo, setIsFileVideo] = useState(false);
  const [method, setMethod] = useState("Fixed Price");
  const [price, setPrice] = useState();
  const [creatorPublicKey, setCreatorPublicKey] = useState("");
  const [royaltyPublicKey, setRoyaltyPublicKey] = useState("");
  const [author, setAuthor] = useState("");

  const [minBid, setMinBid] = useState();
  const [startingDate, setStartingDate] = useState();
  const [endingDate, setEndingDate] = useState();
  const [title, setTitle] = useState("");
  const [metadataTitles, setMetadataTitles] = useState("");
  const [metadataFeilds, setMetadataFeilds] = useState("");
  const [description, setDescription] = useState("");
  const [royalties, setRoyalties] = useState(10);
  const [allCollections, setAllCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState();

  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [newCollectionLogo, setNewCollectionLogo] = useState();
  const [newCollectionName, setNewCollectionName] = useState();
  const [newCollectionDesc, setNewCollectionDesc] = useState();
  const [newCollectionSymbol, setNewCollectionSymbol] = useState();
  const [newCollectionUrl, setNewCollectionUrl] = useState();
  const [newCollection, setNewCollection] = useState();
  const isAdmin = user.address === "0x89eaaf9064d3dd94990b9c571383e9c4d2f295de";

  const [defaultCollectionCreated, setDefaultCollectionCreated] =
    useState(false);

  const [putOnMP, setPutOnMP] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");
  const [createCollectionErrorMessage, setCreateCollectionErrorMessage] =
    useState("");

  const [networkId, setNetworkId] = useState();
  const [isNFTMinted, setIsNFTMinted] = useState(false);
  const [tokenId, setTokenId] = useState();
  const [mintTxnHash, setMintTxnHash] = useState();

  const collectionService = useMemo(() => new CollectionService(), []);
  const nftService = useMemo(() => new NFTService(), []);

  useEffect(() => {
    authGuard(isUserLoggedIn && isAdmin, navigate);

    const getCollections = async () => {
      const data = await collectionService.getCollections();
      data.collections?.push({
        _id: "create",
        name: "Create New",
      });
      setSelectedCollection(newCollection || data.collections[0]);
      setAllCollections(data.collections);
    };

    getCollections();
  }, [
    collectionService,
    user.address,
    isUserLoggedIn,
    isAdmin,
    navigate,
    showCreateCollection,
    newCollection,
  ]);

  // useEffect(async () => {
  //   if (window.ethereum) {
  //     const web3 = new Web3(window.ethereum);
  //     async function validateNetwork() {
  //       const netId = await web3.eth.net.getId();
  //       setNetworkId(netId);
  //       if (netId !== 80001) {
  //         await switchToMatic();
  //       }
  //     }
  //     validateNetwork();
  //     // window.ethereum.on("chainChanged", validateNetwork);
  //   }
  // }, [networkId]);

  const switchToMatic = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x13881" }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x13881",
                chainName: "Mumbai",
                rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer-mumbai.maticvigil.com"],
              },
            ],
          });
        } catch (addError) {
          console.log("Adding Network Error: " + addError.message);
        }
      }
      console.log(error);
    }
  };

  const uploadFile = async (media) => {
    try {
      setFileLoading(true);
      setIsFileVideo(media.type.includes("video"));
      // const added = await client.add(media);
      // const url = `https://ipfs.infura.io/ipfs/${added.path}`;
      const { ipfsHash } = await nftService.AddImageToIPFS(media);
      const url = `https://niftron.infura-ipfs.io/ipfs/${ipfsHash}`;
      setFile(url);
      setFileLoading(false);
    } catch (error) {
      console.log("Error uploading file: ", error);
      setFileLoading(false);
    }
  };

  const FileContent = () =>
    !isFileVideo ? (
      <img src={file || img1} alt="Axies" />
    ) : (
      <ReactPlayer width="100%" controls playing loop url={file} />
    );

  const validateCreateNFT = () => {
    let validInput = true;

    if (!putOnMP) {
      if (
        !file ||
        !selectedCollection ||
        !title ||
        !description ||
        !royalties
        // || !size
      ) {
        validInput = false;
      }
    } else {
      switch (method) {
        case "Time Auctions": {
          if (
            !file ||
            !minBid ||
            !startingDate ||
            !title ||
            !endingDate ||
            !description ||
            !royalties
            // || !size
          ) {
            validInput = false;
          }
          break;
        }
        case "Open For Bids": {
          if (
            !file ||
            !minBid ||
            !title ||
            !endingDate ||
            !description ||
            !royalties
            // || !size
          ) {
            validInput = false;
          }
          break;
        }
        case "Fixed Price":
          if (
            !file ||
            !title ||
            !description ||
            !royalties ||
            !creatorPublicKey ||
            !royaltyPublicKey
          ) {
            validInput = false;
          }
          break;
        default: {
          if (!file || !title || !description || !royalties) {
            validInput = false;
          }
          break;
        }
      }
    }

    return validInput;
  };

  const validateCreateCollection = () => {
    let validInput = true;
    if (
      !newCollectionLogo ||
      !newCollectionDesc ||
      !newCollectionUrl ||
      !newCollectionSymbol ||
      !newCollectionName
    ) {
      validInput = false;
    }

    return validInput;
  };

  const createNFT = async () => {
    setErrorMessage("");

    const metadataTitlesArray = metadataTitles.split(",");
    const metadataValuesArray = metadataFeilds.split(",");

    if (metadataTitlesArray.length !== metadataValuesArray.length) {
      alert("metadata titles and values should be same length");
      return;
    }

    const validInput = validateCreateNFT();
    if (validInput) {
      const ipfsObj = {
        name: title,
        description: description,
        image: file,
        seller_fee_basis_points: parseFloat(royalties) * 100,
        fee_recipient: royaltyPublicKey.toLowerCase(),
        attributes: metadataTitlesArray.map((title, index) => {
          return {
            trait_type: title,
            value: metadataValuesArray[index],
          };
        }),
      };

      const { ipfsHash } = await nftService.AddMetaToIPFS(
        JSON.stringify(ipfsObj)
      );

      let sendingCollections = selectedCollection
      delete sendingCollections.nftDetails;

      const body = {
        file,
        price,
        title,
        description,
        isFileVideo,
        creatorPublicKey: creatorPublicKey.toLowerCase(),
        royaltyPublicKey: royaltyPublicKey.toLowerCase(),
        metadata: ipfsHash,
        isMinted: false,
        mintInitiated: false,
        attributes: metadataTitlesArray.map((title, index) => {
          return {
            trait_type: title,
            value: metadataValuesArray[index],
          };
        }),
        // size,
        category: nftCategories[0],
        method: method === "Fixed Price" ? "Fixed Price" : "Time Auctions",
        royalties: royalties,
        count: "1",
        creator: creatorPublicKey.toLowerCase(),
        seller_fee_basis_points: parseFloat(royalties) * 100,
        fee_recipient: royaltyPublicKey.toLowerCase(),
        owner: creatorPublicKey.toLowerCase(),
        collectionDetails: sendingCollections,
        putOnMarket: false,
        views: 0,
        likes: 0,
        usersLiked: [],
        usersViewed: [],
      };
      if (minBid) {
        body.minBid = minBid;
        body.currentBid = minBid;
        body.bidder = user.address.toLowerCase();
      }
      if (startingDate) {
        body.startingDate = startingDate;
      }
      if (endingDate) {
        body.endingDate = endingDate;
      }
      if (endingDate && !startingDate) {
        body.startingDate = new Date().toISOString().split("T")[0];
      }

      if (method !== "Fixed Price") {
        // TODO: call the minting and createAuction function
        await mintNFT(body);
      } else {
        setIsNFTMinted(false);
        await nftService.createNFT(body);
        navigate(`/profile/${user.address}`);
      }
    } else {
      setErrorMessage("Please fill in all the fields");
    }
  };

  const createCollection = async () => {
    setCreateCollectionErrorMessage("");
    const validInput = validateCreateCollection();
    if (validInput) {
      const body = {
        logo: newCollectionLogo,
        name: newCollectionName,
        description: newCollectionDesc,
        symbol: newCollectionSymbol,
        url: newCollectionUrl,
        creator: user.address.toLowerCase(),
        owner: user.address.toLowerCase(),
        nfts: [],
      };

      try {
        await collectionService.createCollection(body);
        setShowCreateCollection(false);
        setNewCollection(body);
        const newData = (await collectionService.getCollections()).collections;
        setSelectedCollection(
          newData.find((c) => c.name === newCollectionName)
        );
        closeCollectionModal();
      } catch (err) {
        setCreateCollectionErrorMessage(err.response.data.collection);
      }
    } else {
      setCreateCollectionErrorMessage("Please fill in all the fields");
    }
  };

  const mintNFT = async (data) => {
    console.log(method);
    if (!isNFTMinted) {
      try {
        const ipfsObj = {
          name: data.title,
          description: data.description,
          image: data.file,
          author
        };
        // const ipfsData = await client.add(JSON.stringify(ipfsObj));
        // const tokenUri = `https://ipfs.infura.io/ipfs/${ipfsData.path}`;
        const { ipfsHash } = await nftService.AddToIPFS(
          JSON.stringify(ipfsObj)
        );
        const tokenUri = `https://niftron.infura-ipfs.io/ipfs/${ipfsHash}`;
        // Initialize Web3
        const web3 = new Web3(window.ethereum);
        if (networkId !== 80001) {
          await switchToMatic();
        }
        let contractId = "0x4f507e99bbf601cd0b0a07634536f38e8c565b1a";
        // if (networkId === 80001) {
        //   contractId = "0x4f507E99bBf601cD0b0A07634536F38E8c565b1a";
        // }
        const contractABI = require("../utils/abi/ENFTABI.json");
        const NFTCONTRACT = new web3.eth.Contract(contractABI, contractId);
        // console.log(data.startingDate);
        // console.log(startTime);
        await NFTCONTRACT.methods
          .mintNFT(user.address, tokenUri)
          .send({ from: user.address })
          .on("receipt", async function (receipt) {
            console.log(receipt);
            const tokenId = receipt.events.Transfer.returnValues[2];
            setMintTxnHash(receipt.transactionHash);
            setTokenId(tokenId);
            setIsNFTMinted(true);
            console.log(tokenId);
          });
      } catch (error) {
        console.log("Mint Error: ", error);
      }
    } else {
      alert("Token already minted");
    }
  };

  const changeNFTType = (type) => {
    setMethod(type);
  };

  const createAuction = async () => {
    console.log("Auction Creatiion");
    if (
      (isNFTMinted && method === "Time Auctions") ||
      method === "Open For Bids"
    ) {
      // Initialize Web3
      const web3 = new Web3(window.ethereum);
      if (networkId !== 80001) {
        await switchToMatic();
      }
      const body = {
        file,
        price,
        title,
        description,
        creatorPublicKey,
        royaltyPublicKey,
        isMinted: false,
        // size,
        // category,
        method: method === "Fixed Price" ? "Fixed Price" : "Time Auctions",
        royalties,
        count: "1",
        creator: creatorPublicKey,
        owner: creatorPublicKey,
        collectionDetails: selectedCollection,
        putOnMarket: false,
        views: 0,
        likes: 0,
        usersLiked: [],
        usersViewed: [],
      };
      if (minBid) {
        body.minBid = minBid;
        body.currentBid = minBid;
        body.bidder = user.address;
      }
      if (startingDate) {
        body.startingDate = startingDate;
      } else {
        body.startingDate = 0;
      }
      if (endingDate) {
        body.endingDate = endingDate;
      }
      if (endingDate && !startingDate) {
        body.startingDate = new Date().toISOString().split("T")[0];
      }
      let contractId;
      if (networkId === 80001) {
        contractId = "0x4f507E99bBf601cD0b0A07634536F38E8c565b1a";
      }
      const contractABI = require("../utils/abi/ENFTABI.json");
      const NFTCONTRACT = new web3.eth.Contract(contractABI, contractId);
      let auctionContractId = "0xA7BE8772CFa4AAC73Bb41c734e029a97B7fb70E7";
      const auctionContractAbi = require("../utils/abi/AuctionContract.json");
      const AUCTIONCONTRACT = new web3.eth.Contract(
        auctionContractAbi,
        auctionContractId
      );
      const startingPriceWei = web3.utils.toBN(parseFloat(body.minBid) * 1e18);
      const endingPriceWei = 0;
      const startTime = Date.parse(body.startingDate) / 1000;
      const endTime = Date.parse(body.endingDate) / 1000;
      const duration = endTime - startTime;
      const seller = user.address;
      console.log(body);
      try {
        const tokenOwner = await NFTCONTRACT.methods.ownerOf(tokenId).call();
        if (tokenOwner === user.address) {
          // await NFTCONTRACT.methods
          //   .setApprovalForAll(auctionContractId, true)
          //   .send({ from: user.address })
          //   .on("receipt", async (receipt) => {
          //     console.log("approval set");
          const approvedForAll = await NFTCONTRACT.methods
            .isApprovedForAll(user.address, auctionContractId)
            .call();
          const approvedTo = await NFTCONTRACT.methods
            .getApproved(tokenId)
            .call();
          if (approvedForAll || approvedTo === auctionContractId) {
            await AUCTIONCONTRACT.methods
              .createAuction(
                tokenId,
                startingPriceWei,
                endingPriceWei,
                startTime,
                duration,
                seller
              )
              .send({ from: user.address })
              .on("receipt", async function (receipt) {
                console.log("Auction Create Receipt: ", receipt);
                const auctionDetails = await AUCTIONCONTRACT.methods
                  .getAuction(tokenId)
                  .call();
                console.log(auctionDetails);
                body.isMinted = true;
                body.auctionCreationtxnHash = receipt.transactionHash;
                body.mintTxnHash = mintTxnHash;
                body.tokenId = tokenId;
                await nftService.createNFT(body);
                navigate(`/profile/${user.address}`);
              });
            //});
          } else {
            alert("Token not approved by owner!");
          }
        }
      } catch (error) {
        console.log("Auction Creation Error", error);
      }
    }
  };

  const closeCollectionModal = () => {
    setShowCreateCollection(false);
    setNewCollectionLogo(undefined);
    setNewCollectionName("");
    setNewCollectionDesc("");
    setNewCollectionSymbol("");
    setNewCollectionUrl("");
    setCreateCollectionErrorMessage("");
  };

  return (
    <div className="create-item">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Create Item</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Pages</Link>
                  </li>
                  <li>Create Item</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-create-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-12">
              <h4 className="title-create-item">Preview item</h4>
              <div className="sc-card-product">
                <div className="card-media">
                  <span>
                    {file ? <FileContent /> : <img alt="loading" src={img1} />}
                  </span>
                  {/* <span className="wishlist-button heart">
                    <span className="number-like"> 100</span>
                  </span> */}
                  {method !== "Fixed Price" && (
                    <div className="featured-countdown">
                      <span className="slogan"></span>
                      <Countdown
                        date={
                          new Date(startingDate).getTime() ||
                          Date.now() + 500000000
                        }
                      >
                        <span>You are good to go!</span>
                      </Countdown>
                    </div>
                  )}
                </div>
                <div className="card-title">
                  <h5>{title}</h5>
                  {/* <div className="tags">polyon</div> */}
                </div>
                <div className="meta-info">
                  <div className="author">
                    {/* <div className="avatar">
                      <img src={userDetails?.profileImage} alt="Axies" />
                    </div> */}
                    <div className="info">
                      <span>Owned By</span>
                      <h6>
                        {" "}
                        <span>{truncate(creatorPublicKey, 15)}</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="title-create-collection">Collection *</h4>
              <div className="seclect-box">
                <div id="item-create" className="dropdown">
                  <Link to="#" className="btn-selector nolink">
                    {selectedCollection?.name}
                  </Link>
                  <ul>
                    {allCollections?.map((coll) => (
                      <li
                        key={coll._id}
                        onClick={() =>
                          coll._id === "create"
                            ? setShowCreateCollection(!showCreateCollection)
                            : setSelectedCollection(coll)
                        }
                      >
                        <span>{coll.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-6 col-md-12 col-12">
              <div className="form-create-item">
                <br />
                <br />

                <form action="#">
                  <h4 className="title-create-item">Upload File *</h4>
                  <label className="uploadFile">
                    {!fileLoading && (
                      <span className="filename">
                        PNG, JPG, GIF, WEBP or MP4. Max 200mb.
                      </span>
                    )}
                    {fileLoading && (
                      <span className="filename">
                        Please be patient until we upload your file to ipfs...
                      </span>
                    )}
                    <input
                      disabled={fileLoading}
                      type="file"
                      className="inputfile form-control"
                      name="file"
                      onChange={(e) => uploadFile(e.target.files[0])}
                    />

                    <div>                    <span>or</span>

                      <input
                        type="text"
                        required
                        placeholder="Enter IPFS Video URL"
                        value={file}
                        onChange={(e) => {
                          setFile(e.target.value)
                          setIsFileVideo(true)
                        }
                        }
                      />
                    </div>

                  </label>
                </form>
                {/* <div>
                  <label
                    className="label-create-item-show-on-mp"
                    htmlFor="put-on-market-place"
                  >
                    <h4>Put on marketplace</h4>
                    <Switch
                      checked={putOnMP}
                      onChange={() => setPutOnMP(!putOnMP)}
                      onColor="#9085ff"
                      onHandleColor="#097292"
                      handleDiameter={15}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="put-on-market-place"
                    />
                  </label>
                </div> */}
                <div className="flat-tabs tab-create-item">
                  {/* {putOnMP && (
                    <h4 className="title-create-item">Select method</h4>
                  )} */}
                  <Tabs>
                    {/* {putOnMP && (
                      <TabList>
                        <Tab onClick={() => changeNFTType("Fixed Price")}>
                          <span className="icon-fl-tag"></span>Fixed Price
                        </Tab>
                        <Tab onClick={() => changeNFTType("Time Auctions")}>
                          <span className="icon-fl-clock"></span>Time Auctions
                        </Tab>
                        <Tab onClick={() => changeNFTType("Open For Bids")}>
                          <span className="icon-fl-icon-22"></span>Open For Bids
                        </Tab>
                      </TabList>
                    )} */}

                    {/* {putOnMP && method === "Fixed Price" ? (
                      <>
                        {" "}
                        <h5 className="title-create-item">
                          Note: the NFT will be lazy minted when user purchase
                          it
                        </h5>
                      </>
                    ) : (
                      <p></p>
                    )} */}

                    <TabPanel>
                      <form action="#">
                        {/* {putOnMP && (
                          <>
                            <h4 className="title-create-item">Price</h4>
                            <input
                              type="text"
                              placeholder="Enter price for one item (USD)"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </>
                        )} */}

                        <h4 className="title-create-item">
                          Creator Publickey *
                        </h4>
                        <input
                          type="text"
                          required
                          placeholder="Enter creator publickey"
                          value={creatorPublicKey}
                          onChange={(e) => setCreatorPublicKey(e.target.value)}
                        />
                        <h4 className="title-create-item">
                          Royalty Publickey *
                        </h4>
                        <input
                          type="text"
                          required
                          placeholder="Enter royalty publickey"
                          value={royaltyPublicKey}
                          onChange={(e) => setRoyaltyPublicKey(e.target.value)}
                        />
                        <h4 className="title-create-item">
                          Author *
                        </h4>
                        <input
                          type="text"
                          required
                          placeholder="Enter Author Name"
                          value={author}
                          onChange={(e) => setAuthor(e.target.value)}
                        />
                        <CommonFields
                          title={title}
                          setTitle={setTitle}
                          description={description}
                          setDescription={setDescription}
                          royalties={royalties}
                          setRoyalties={setRoyalties}
                          metadataTitles={metadataTitles}
                          setMetadataTitles={setMetadataTitles}
                          metadataFeilds={metadataFeilds}
                          setMetadataFeilds={setMetadataFeilds}
                          // size={size}
                          // setSize={setSize}
                          // category={category}
                          // setCategory={setCategory}
                          allCollections={allCollections}
                          selectedCollection={selectedCollection}
                          setSelectedCollection={setSelectedCollection}
                          showCreateCollection={showCreateCollection}
                          setShowCreateCollection={setShowCreateCollection}
                        />
                      </form>
                    </TabPanel>
                    <TabPanel>
                      <form action="#">
                        {putOnMP && (
                          <>
                            <h4 className="title-create-item">Minimum bid</h4>
                            <input
                              value={minBid}
                              onChange={(e) => setMinBid(e.target.value)}
                              type="text"
                              placeholder="enter minimum bid"
                            />
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="title-create-item">
                                  Starting date
                                </h5>
                                <input
                                  type="datetime-local"
                                  name="bid_starting_date"
                                  id="bid_starting_date"
                                  className="form-control"
                                  min="1997-01-01"
                                  value={startingDate}
                                  onChange={(e) =>
                                    setStartingDate(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <h4 className="title-create-item">
                                  Expiration date
                                </h4>
                                <input
                                  type="datetime-local"
                                  name="bid_expiration_date"
                                  id="bid_expiration_date"
                                  className="form-control"
                                  value={endingDate}
                                  onChange={(e) =>
                                    setEndingDate(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <CommonFields
                          title={title}
                          setTitle={setTitle}
                          description={description}
                          setDescription={setDescription}
                          royalties={royalties}
                          setRoyalties={setRoyalties}
                          // size={size}
                          // setSize={setSize}
                          // category={category}
                          // setCategory={setCategory}
                          allCollections={allCollections}
                          selectedCollection={selectedCollection}
                          setSelectedCollection={setSelectedCollection}
                          showCreateCollection={showCreateCollection}
                          setShowCreateCollection={setShowCreateCollection}
                        />
                      </form>
                    </TabPanel>
                    <TabPanel>
                      <form action="#">
                        {putOnMP && (
                          <>
                            <h4 className="title-create-item">Minimum bid</h4>
                            <input
                              value={minBid}
                              onChange={(e) => setMinBid(e.target.value)}
                              type="text"
                              placeholder="enter minimum bid"
                            />

                            <div className="row">
                              <div className="col-md-12">
                                <h4 className="title-create-item">
                                  Expiration date
                                </h4>
                                <input
                                  type="datetime-local"
                                  name="bid_expiration_date"
                                  id="bid_expiration_date2"
                                  className="form-control"
                                  value={endingDate}
                                  onChange={(e) =>
                                    setEndingDate(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <CommonFields
                          title={title}
                          setTitle={setTitle}
                          description={description}
                          setDescription={setDescription}
                          royalties={royalties}
                          setRoyalties={setRoyalties}
                          allCollections={allCollections}
                          selectedCollection={selectedCollection}
                          setSelectedCollection={setSelectedCollection}
                          showCreateCollection={showCreateCollection}
                          setShowCreateCollection={setShowCreateCollection}
                        />
                      </form>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
              {errorMessage && (
                <div
                  style={{
                    marginTop: "3rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ color: "red" }}>{errorMessage}</p>
                </div>
              )}
              <div
                style={{
                  marginTop: "3rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button onClick={createNFT}>Create NFT</button>
                {method === "Time Auctions" || method === "Open For Bids" ? (
                  <>
                    <button onClick={createAuction} disabled={!isNFTMinted}>
                      Create Auction
                    </button>
                  </>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateCollection
        onHide={closeCollectionModal}
        onCreate={createCollection}
        ipfsClient={client}
        logo={newCollectionLogo}
        setLogo={setNewCollectionLogo}
        show={showCreateCollection}
        name={newCollectionName}
        setName={setNewCollectionName}
        desc={newCollectionDesc}
        setDesc={setNewCollectionDesc}
        symbol={newCollectionSymbol}
        setSymbol={setNewCollectionSymbol}
        url={newCollectionUrl}
        setUrl={setNewCollectionUrl}
        error={createCollectionErrorMessage}
      />
      <Footer />
    </div>
  );
};

export default CreateItem;
